import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators} from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { State } from '../../classes/state';
import { Application } from '../../classes/application';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
	@ViewChild('recaptchaRef')
	recaptchaRef: RecaptchaComponent;

	token = "";
	showApplication = false;

	applicationForm: FormGroup = new FormGroup({});
	captcha:string = "";
	submitted: Boolean = false;
	errors: Array<any> = [];
	missingCaptcha:Boolean = false;
	response:any;
	submitSuccess:Boolean = false;
	submitError:Boolean = false;
	formError:string;
	applicationNumber:string;

	anyPets: Boolean = false;
	dogExperience: Boolean = false;

	errorLabels = {
		confirmAtLeast18YearsOfAge: 'Confirm at least 25 years of age',
		confirmHaveConsentOfAddAdultsInHousehold: 'Confirm consent of all adults in household',
		confirmUnderstandRightToDenyOrAcceptApplication: 'Confirm understanding of application terms',
		adultsApplyingForAdoption: 'Missing names of applicants',
		country: 'Invalid country',
		address: 'Missing address',
		city: 'Missing city',
		state: 'Invalid state',
		zip: 'Missing ZIP',
		phoneNumbers: 'Invalid phone number',
		email: 'Invalid email',
		applicantIntroduction: 'Missing introduction',
		numberAdultsInHousehold: 'Missing number of adults in household',
		numberChildren: 'Missing number of children in househole',
		childrenAges: 'Missing children ages',
		childrenDogExperience: 'Missing children dog experience selection',
		employment: 'Missing employment information',
		rentOrOwn: 'Missing rent/own home selection',
		homeType: 'Missing home type selection',
		homeTypeDescribe: 'Missing home type description',
		landlordName: 'Missing landlord name',
		landlordPhone: 'Missing landlord phone number',
		landlordEmail: 'Missing landlord email',
		durationLivedAtAddressYears: 'Missing years lived at current address',
		durationLivedAtAddressMonths: 'Missing months lived at current address',
		previousAddress: 'Missing previous address',
		preferredAge: 'Missing preferred age selection',
		preferredSex: 'Missing preferred sex selection',
		preferredActivityLevel: 'Missing preferred activity level selection',
		importantConsiderations: 'Missing important considerations',
		importantConsiderationsOther: 'Missing important considerations',
		reasonForAdopting: 'Missing adoption reason',
		dogApplyingFor: 'Missing dog applying for',
		dogApplyingForExplain: 'Missing dog applying for explanation',
		suggestOtherDogsOkay: 'Missing dog suggestion selection',
		herdingBreedExperience: 'Missing herding breed experience selection',
		breedKnowledge: 'Missing breed knowledge',
		behaviorConcerns: 'Missing behavior concerns',
		hasPets: 'Missing pet selection',
		pastPets: 'Missing pet selection',
		pets: 'Missing pet information',
		heartwormPrevention: 'Missing prevention selection',
		heartwormPreventionExplain: 'Missing prevention explanation',
		heartwormPreventionBrand: 'Missing prevention brand',
		fleaTickPrevention: 'Missing prevention selection',
		fleaTickPreventionExplain: 'Missing prevention explanation',
		fleaTickPreventionBrand: 'Missing prevention brand',
		yearlyTesting: 'Missing yearly testing selection',
		petExperience: 'Missing pet experience',
		allPetsYearlyVetVisitAndVaccinesCurrent: 'Missing annual care selection',
		allPetsYearlyVetVisitAndVaccinesCurrentExplain: 'Missing annual care explanation',
		allPetsSpayedNeutered: 'Missing spay/neuter selection',
		allPetsSpayedNeuteredExplain: 'Missing spay/neuter explanation',
		trainingPlan: 'Missing training plan selection',
		trainingPlanExplain: 'Missing training plan explanation',
		awareLicenseLeashAnimalLaws: 'Missing leash/animal laws selection',
		fencedYard: 'Missing fenced yard selection',
		fencedYardDescribe: 'Missing fenced yard description',
		exerciseBathroomNeedsDescribe: 'Missing bathroom & exercise needs description',
		poolOrPond: 'Missing pool/pond selection',
		poolOrPondDescribe: 'Missing pool/pond description',
		aloneHours: 'Missing alone hours',
		aloneLocation: 'Missing dog location',
		ownCrate: 'Missing crate information',
		sleepLocation: 'Missing sleep location',
		vacationCare: 'Missing vacation care information',
		allergies: 'Missing allergy information',
		responsibleParty: 'Missing responsible party name',
		returnedAnimal: 'Missing returned animal selection',
		returnedAnimalExplain: 'Missing returned animal explanation',
		homeVisitOkay: 'Missing home visit selection',
		vetName: 'Missing vet name',
		vetPhone: 'Missing vet phone number',
		vetOwnerOnFile: 'Missing owner name',
		vetPetsOnFile: 'Missing pets on file',
		vetPreviousPetsName: 'Missing past vet name',
		vetPreviousPetsPhone: 'Missing past vet phone number',
		vetNoPetsName: 'Missing name of vet you plan on using',
		vetNoPetsPhone: 'Missing phone number of vet you plan on using',
		certify: 'Missing certification',
		signature: 'Missing digital signiture',
	};

	countries: Array<string> = [
		"United States",
		"Canada"
	];

	states: Array<State> = [
		{
			name:'Alabama',
			abbreviation: 'AL'
		}, {
			name:'Alaska',
			abbreviation: 'AK'
		}, {
			name:'American Samoa',
			abbreviation: 'AS'
		}, {
			name:'Arizona',
			abbreviation: 'AZ'
		}, {
			name:'Arkansas',
			abbreviation: 'AR'
		}, {
			name:'California',
			abbreviation: 'CA'
		}, {
			name:'Colorado',
			abbreviation: 'CO'
		}, {
			name:'Connecticut',
			abbreviation: 'CT'
		}, {
			name:'Delaware',
			abbreviation: 'DE'
		}, {
			name:'District of Columbia',
			abbreviation: 'DC'
		}, {
			name:'States of Micronesia',
			abbreviation: 'FM'
		}, {
			name:'Florida',
			abbreviation: 'FL'
		}, {
			name:'Georgia',
			abbreviation: 'GA'
		}, {
			name:'Guam',
			abbreviation: 'GU'
		}, {
			name:'Hawaii',
			abbreviation: 'HI'
		}, {
			name:'Idaho',
			abbreviation: 'ID'
		}, {
			name:'Illinois',
			abbreviation: 'IL'
		}, {
			name:'Indiana',
			abbreviation: 'IN'
		}, {
			name:'Iowa',
			abbreviation: 'IA'
		}, {
			name:'Kansas',
			abbreviation: 'KS'
		}, {
			name:'Kentucky',
			abbreviation: 'KY'
		}, {
			name:'Louisiana',
			abbreviation: 'LA'
		}, {
			name:'Maine',
			abbreviation: 'ME'
		}, {
			name:'Marshall Islands',
			abbreviation: 'MH'
		}, {
			name:'Maryland',
			abbreviation: 'MD'
		}, {
			name:'Massachusetts',
			abbreviation: 'MA'
		}, {
			name:'Michigan',
			abbreviation: 'MI'
		}, {
			name:'Minnesota',
			abbreviation: 'MN'
		}, {
			name:'Mississippi',
			abbreviation: 'MS'
		}, {
			name:'Missouri',
			abbreviation: 'MO'
		}, {
			name:'Montana',
			abbreviation: 'MT'
		}, {
			name:'Nebraska',
			abbreviation: 'NE'
		}, {
			name:'Nevada',
			abbreviation: 'NV'
		}, {
			name:'New Hampshire',
			abbreviation: 'NH'
		}, {
			name:'New Jersey',
			abbreviation: 'NJ'
		}, {
			name:'New Mexico',
			abbreviation: 'NM'
		}, {
			name:'New York',
			abbreviation: 'NY'
		}, {
			name:'North Carolina',
			abbreviation: 'NC'
		}, {
			name:'North Dakota',
			abbreviation: 'ND'
		}, {
			name:'Northern Mariana Islands',
			abbreviation: 'MP'
		}, {
			name:'Ohio',
			abbreviation: 'OH'
		}, {
			name:'Oklahoma',
			abbreviation: 'OK'
		}, {
			name:'Oregan',
			abbreviation: 'OR'
		}, {
			name:'Palau',
			abbreviation: 'PW'
		}, {
			name:'Pennsilvania',
			abbreviation: 'PA'
		}, {
			name:'Puerto Rico',
			abbreviation: 'PR'
		}, {
			name:'Rhode Island',
			abbreviation: 'RI'
		}, {
			name:'South Carolina',
			abbreviation: 'SC'
		}, {
			name:'South Dakota',
			abbreviation: 'SD'
		}, {
			name:'Tennessee',
			abbreviation: 'TN'
		}, {
			name:'Texas',
			abbreviation: 'TX'
		}, {
			name:'Utah',
			abbreviation: 'UT'
		}, {
			name:'Vermont',
			abbreviation: 'VT'
		}, {
			name:'Virgin Islands',
			abbreviation: 'VI'
		}, {
			name:'Virginia',
			abbreviation: 'VA'
		}, {
			name:'Washington',
			abbreviation: 'WA'
		}, {
			name:'West Virginia',
			abbreviation: 'WV'
		}, {
			name:'Wisconsin',
			abbreviation: 'WI'
		}, {
			name:'Wyoming',
			abbreviation: 'WY'
		}
	];

	provinces: Array<State> = [
		{
			name: 'Alberta',
			abbreviation: 'AB'
		}, {
			name: 'British Columbia',
			abbreviation: 'BC'
		}, {
			name: 'Manitoba',
			abbreviation: 'MB'
		}, {
			name: 'New Brunswick',
			abbreviation: 'NB'
		}, {
			name: 'Newfoundland and Labrador',
			abbreviation: 'NL'
		}, {
			name: 'Northwest Territories',
			abbreviation: 'NT'
		}, {
			name: 'Nova Scotia',
			abbreviation: 'NS'
		}, {
			name: 'Nunavut',
			abbreviation: 'NU'
		}, {
			name: 'Ontario',
			abbreviation: 'ON'
		}, {
			name: 'Prince Edward Island',
			abbreviation: 'PE'
		}, {
			name: 'Quebec',
			abbreviation: 'QC'
		}, {
			name: 'Saskatchewan',
			abbreviation: 'SK'
		}, {
			name: 'Yukon Territory',
			abbreviation: 'YT'
		}
	];

	phoneTypes: Array<string> = [
		"Home",
		"Work",
		"Cell"
	];

	employmentTypes: Array<string> = [
		"Employed Full-Time",
		"Employed Part-Time",
		"Retired",
		"Other",
	];

	homeTypes: Array<string> = [
		"House",
		"Apartment",
		"Condo",
		"Other"
	];

	ages: Array<string> = [
		"Puppy (under 6 mo)",
		"Young Adult (6 mo- 2 yrs)",
		"Adult",
		"Senior",
		"No Preference"
	];

	activityLevels: Array<string> = [
		"Very active (suitable for sports or running companion)",
		"Somewhat active (a dog to walk with or perform moderate activities)",
		"A mellow couch potato"
	];

	important: Array<string> = [
		"Good with cats",
		"Good with other dogs",
		"Good with kids",
		"Other"
	];

	petTypes: Array<string> = [
		"Dog",
		"Cat",
		"Other"
	];

  	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
	  	private http: HttpClient,
	) {}

	ngOnInit(): void {
		// Check that application token is valid
		this.route.queryParams.subscribe(params => {
			this.token = params.app;

			if (this.token) {
				this.http.post('./assets/token-check.php', { token: this.token }, {
						responseType: 'json'
					}).subscribe({
					next: data => {
						const response:any = data;
						// console.log(data);

						if (response.success) {
							this.buildForm();

							this.restoreSavedApplicationData(response.data);

							this.saveOnValueChanges();

						} else {
							this.router.navigate(['']);
						}

					},
					error: error => {
						console.error('Error restoring application form', error);
						this.formError = error.message;
					}
				});
			} else {
				this.router.navigate(['']);
			}
        });
	}

	buildForm(): void {
		this.showApplication = true;
		this.applicationForm = this.fb.group({
			confirmAtLeast18YearsOfAge: [false, [Validators.requiredTrue]],
			confirmHaveConsentOfAddAdultsInHousehold: [false, [Validators.requiredTrue]],
			confirmUnderstandRightToDenyOrAcceptApplication: [false, [Validators.requiredTrue]],
			adultsApplyingForAdoption: this.fb.array([this.createAdult()]),
			numberAdultsInHousehold: ['', [Validators.required, Validators.min(1)]],
			numberChildren: ['', [Validators.required, Validators.min(0)]],
			childrenAges: this.fb.array([]),
			childrenDogExperience: ['', []],
			country: ['United States', [Validators.required]],
			address: ['', [Validators.required]],
			city: ['', [Validators.required]],
			state: ['', [Validators.required]],
			zip: ['', [Validators.required]],
			phoneNumbers: this.fb.array([this.createPhoneNumber()]),
			email: ['', [Validators.required, Validators.email]],
			applicantIntroduction: ['', [Validators.required]],
			employment: this.fb.array([this.createEmployment()]),
			rentOrOwn: ['', [Validators.required]],
			homeType: ['', [Validators.required]],
			homeTypeDescribe: ['', []],
			landlordName: ['', []],
			landlordPhone: ['', []],
			landlordEmail: ['', []],
			durationLivedAtAddressYears: ['', [Validators.required]],
			durationLivedAtAddressMonths: ['0', [Validators.required, Validators.max(12)]],
			previousAddress: ['', []],
			preferredAge: this.fb.array([]),
			preferredSex: ['', [Validators.required]],
			preferredActivityLevel: this.fb.array([]),
			importantConsiderations: this.fb.array([]),
			importantConsiderationsOther: ['', []],
			reasonForAdopting: ['', [Validators.required]],
			dogApplyingFor: ['', []],
			dogApplyingForExplain: ['', []],
			suggestOtherDogsOkay: ['', [Validators.required]],
			herdingBreedExperience: ['', [Validators.required]],
			breedKnowledge: ['', []],
			behaviorConcerns: ['', [Validators.required]],
			hasPets: ['', [Validators.required]],
			pastPets: ['', []],
			pets: this.fb.array([]),
			heartwormPrevention: ['', []],
			heartwormPreventionExplain: ['', []],
			heartwormPreventionBrand: ['', []],
			fleaTickPrevention: ['', []],
			fleaTickPreventionExplain: ['', []],
			fleaTickPreventionBrand: ['', []],
			yearlyTesting: ['', []],
			petExperience: ['', []],
			allPetsYearlyVetVisitAndVaccinesCurrent: ['', []],
			allPetsYearlyVetVisitAndVaccinesCurrentExplain: ['', []],
			allPetsSpayedNeutered: ['', []],
			allPetsSpayedNeuteredExplain: ['', []],
			trainingPlan: ['', [Validators.required]],
			trainingPlanExplain: ['', []],
			awareLicenseLeashAnimalLaws: ['', [Validators.required]],
			fencedYard: ['', [Validators.required]],
			fencedYardDescribe: ['', []],
			exerciseBathroomNeedsDescribe: ['', [Validators.required]],
			poolOrPond: ['', [Validators.required]],
			poolOrPondDescribe: ['', []],
			aloneHours: ['', [Validators.required]],
			aloneLocation: ['', [Validators.required]],
			ownCrate: ['', [Validators.required]],
			sleepLocation: ['', [Validators.required]],
			vacationCare: ['', [Validators.required]],
			allergies: ['', [Validators.required]],
			responsibleParty: ['', [Validators.required]],
			returnedAnimal: ['', [Validators.required]],
			returnedAnimalExplain: ['', []],
			homeVisitOkay: ['', [Validators.required]],
			vetName: ['', []],
			vetPhone: ['', []],
			vetOwnerOnFile: ['', []],
			vetPetsOnFile: this.fb.array([]),
			vetPreviousPetsName: ['', []],
			vetPreviousPetsPhone: ['', []],
			vetNoPetsName: ['', []],
			vetNoPetsPhone: ['', []],
			certify: [false, [Validators.requiredTrue]],
			signature: ['', [Validators.required]],
			sendEmail: [true, [Validators.required]],
	    });

		this.setConditionalValidators();
		this.setPets();
	}

	setConditionalValidators() {
		this.setControlValidationTrigger('childrenDogExperience', 'numberChildren', numberChildren=>numberChildren > 0);
		this.setControlValidationTrigger('homeTypeDescribe', 'homeType', 'Other');
		this.setControlValidationTrigger('landlordName', 'rentOrOwn', 'Rent');
		this.setControlValidationTrigger('landlordPhone', 'rentOrOwn', 'Rent');
		this.setControlValidationTrigger('landlordEmail', 'rentOrOwn', 'Rent');
		this.setControlValidationTrigger('previousAddress', 'durationLivedAtAddressYears', durationLivedAtAddressYears=>durationLivedAtAddressYears < 2);
		this.setControlValidationTrigger('importantConsiderationsOther', 'importantConsiderations', 'Other');
		this.setControlValidationTrigger('breedKnowledge', 'herdingBreedExperience', 'No');
		this.setControlValidationTrigger('yearlyTesting', 'pets', pets=>pets.filter(pet => pet.type === 'Dog').length > 0);
		this.setControlValidationTrigger('petExperience', 'hasPets', 'No');
		this.setControlValidationTrigger('allPetsYearlyVetVisitAndVaccinesCurrentExplain', 'allPetsYearlyVetVisitAndVaccinesCurrent', 'No');
		this.setControlValidationTrigger('allPetsSpayedNeuteredExplain', 'allPetsSpayedNeutered', 'No');
		this.setControlValidationTrigger('trainingPlanExplain', 'trainingPlan', 'No');
		this.setControlValidationTrigger('fencedYardDescribe', 'fencedYard', 'Yes');
		this.setControlValidationTrigger('poolOrPondDescribe', 'poolOrPond', 'Yes');
		this.setControlValidationTrigger('returnedAnimalExplain', 'returnedAnimal', 'Yes');
	}

	restoreSavedApplicationData(responseData) {
		this.restoreArray(responseData, 'adultsApplyingForAdoption', this.addAdultApplyingForAdoption);
		this.restoreArray(responseData, 'childrenAges', this.addChild);
		this.restoreArray(responseData, 'phoneNumbers', this.addPhoneNumber);
		this.restoreArray(responseData, 'employment', this.addEmployment);
		this.restoreArray(responseData, 'pets', this.addPet);
		this.restoreArray(responseData, 'vetPetOnFile', this.addVetPetOnFile);

		this.restoreCheckedState(responseData, 'preferredAge');
		this.restoreCheckedState(responseData, 'preferredActivityLevel');
		this.restoreCheckedState(responseData, 'importantConsiderations');

		try {
			this.applicationForm.patchValue(responseData);
		} catch (e) {
			console.error('Error patching data: ', e);
		}
		
		this.setPets();
	}

	saveOnValueChanges(): void {
		this.applicationForm.valueChanges.pipe(
			debounceTime(500),
			distinctUntilChanged()
		).subscribe(application => {
			this.http.post('./assets/application-save.php', {token: this.token, ...application}, {
				responseType: 'text'
			}).subscribe({
				next: data => {
					console.log(data);
				},
				error: error => {
					console.error('Error saving application!', error);
				}
			});
		});
	}

	restoreArray(responseData, control: string, addItem: Function) {
		if(responseData[control] && responseData[control].length) {
			for (let i = 1; i < responseData[control].length; i++) {
				addItem.call(this);
			}
		}
	}

	restoreCheckedState(responseData, control: string) {
		if(responseData[control] && responseData[control].length) {
			for (let i = 0; i < responseData[control].length; i++) {
				this.onCheckChange({
					checked: true,
					source: {
						name: responseData[control][i]
					}
				}, control);
			}
		}
	}

	onCheckChange(event, formControlName: string) {
		const formArray: FormArray = this.applicationForm.get(formControlName) as FormArray;

		if (event.checked) {
			// Add a new control in the arrayForm
			formArray.push(new FormControl(event.source.name));
		} else {
			// find the unselected element
			let i: number = 0;
			formArray.controls.forEach((ctrl: FormControl) => {
				if(ctrl.value == event.source.name) {
					// Remove the unselected element from the arrayForm
					formArray.removeAt(i);
					return;
				}
				i++;
			});
		}
	}

	dataContains(control, value: string) {
		return (this.applicationForm.controls[control] as FormArray).controls.filter(control => control.value === value).length;
	}

	setPets() {
		const hasPets = this.applicationForm.get('hasPets');
		const pastPets = this.applicationForm.get('pastPets');

		this.anyPets = hasPets.value === 'Yes' || pastPets.value === 'Yes';

		const numberOfPets = this.getPets().length;

		if (this.anyPets && numberOfPets === 0) {
			this.addPet();
		} else if (hasPets.value !== 'Yes' && pastPets.value !== 'Yes') {
			for (let index = 0; index < numberOfPets; index++) {
				this.removePet(0);
			}
		}

		this.setConditionalValidatorsForPets(this.anyPets, hasPets.value === 'Yes', pastPets.value === 'Yes');

		this.setDogExperience();
	}

	setConditionalValidatorsForPets(anyPets: Boolean, hasPets: Boolean, pastPets: Boolean) {
		this.setRequired(['vetName', 'vetPhone'], hasPets);
		this.setRequired(['allPetsYearlyVetVisitAndVaccinesCurrent', 'allPetsSpayedNeutered', 'vetOwnerOnFile'], anyPets);
		this.setRequired(['vetPreviousPetsName', 'vetPreviousPetsPhone'], !hasPets && pastPets);
		this.setRequired(['vetNoPetsName', 'vetNoPetsPhone'], !hasPets && !pastPets);
	}

	setDogExperience() {
		this.dogExperience = (this.applicationForm.value['hasPets'] === 'Yes' || this.applicationForm.value['pastPets'] === 'Yes') && this.applicationForm.value['pets'].filter( pet => pet.type === 'Dog').length > 0;
		this.setConditionalValidatorsForDogs(this.dogExperience);
	}

	setConditionalValidatorsForDogs(dogExperience: Boolean) {
		this.setRequired(['heartwormPrevention', 'fleaTickPrevention'], dogExperience);
		
		if (dogExperience) {
			this.setControlValidationTrigger('heartwormPreventionBrand', 'heartwormPrevention', 'Yes');
			this.setControlValidationTrigger('heartwormPreventionExplain', 'heartwormPrevention', 'No');
			this.setControlValidationTrigger('fleaTickPreventionBrand', 'fleaTickPrevention', 'Yes');
			this.setControlValidationTrigger('fleaTickPreventionExplain', 'fleaTickPrevention', 'No');
		} else {
			this.setRequired(['heartwormPreventionBrand', 'heartwormPreventionExplain', 'fleaTickPreventionBrand', 'fleaTickPreventionExplain'], false);
		}
	}

	setControlValidationTrigger(targetControl: string, triggeredBy: string, validationValueOrFunction: string | Function) {
		this.setComplexControlValidationTrigger(targetControl, triggeredBy, typeof validationValueOrFunction === 'string' ? controlValue=>controlValue === validationValueOrFunction : validationValueOrFunction);
	}

	setComplexControlValidationTrigger(targetControl: string, triggeredBy: string, validationFunction: Function) {
		const control = this.applicationForm.get(targetControl);
		this.applicationForm.get(triggeredBy).valueChanges
		.subscribe(thingThatCausesChangeControl => {
			if (validationFunction(thingThatCausesChangeControl)) {
				control.setValidators([Validators.required]);
			} else {
				control.setValidators([]);
			}
			control.updateValueAndValidity();
		});
	}

	setRequired(controls: string | Array<string>, required: Boolean) {
		if (typeof controls === 'string') {
			this.setFormControlRequired(this.applicationForm.get(controls), required);
		} else {
			controls.forEach(control => {
				this.setFormControlRequired(this.applicationForm.get(control), required);
			});
		}
	}

	setFormControlRequired(formControl, required) {
		if (required) {
			formControl.setValidators([Validators.required]);
		} else {
			formControl.setValidators([]);
		}
		formControl.updateValueAndValidity();
	}

	addAdultApplyingForAdoption() {
		this.addFormGroupItem('adultsApplyingForAdoption', 10, this.createAdult);
	}

	addChild() {
		this.addFormGroupItem('childrenAges', 20, this.createChild);
	}

	addPhoneNumber() {
		this.addFormGroupItem('phoneNumbers', 10, this.createPhoneNumber);
	}

	addEmployment() {
		this.addFormGroupItem('employment', 10, this.createEmployment);
	}

	addPet() {
		this.addFormGroupItem('pets', 20, this.createPet);
	}

	addVetPetOnFile() {
		this.addFormGroupItem('vetPetsOnFile', 20, this.createVetPetOnFile);
	}

	addFormGroupItem(control: string, limit: number, createItemFunction: Function) {
		let array = (this.applicationForm.controls[control] as FormArray);
		if (array.length < limit) {
			array.push(createItemFunction.call(this));
		}
	}

	getAdultsApplyingForAdoption() {
		return this.getFormGroupItem('adultsApplyingForAdoption');
	}

	getChildrenAges() {
		return this.getFormGroupItem('childrenAges');
	}

	getPhoneNumbers() {
		return this.getFormGroupItem('phoneNumbers');
	}

	getEmployment() {
		return this.getFormGroupItem('employment');
	}

	getPets() {
		return this.getFormGroupItem('pets');
	}

	getVetPetsOnFile() {
		return this.getFormGroupItem('vetPetsOnFile');
	}

	getFormGroupItem(control: string) {
		return (this.applicationForm.controls[control] as FormArray).controls;
	}

	removeAdultApplyingForAdoption(index) {
		this.removeFormGroupItem('adultsApplyingForAdoption', index);
	}

	removePhoneNumber(index) {
		this.removeFormGroupItem('phoneNumbers', index);
	}

	removeEmployment(index) {
		this.removeFormGroupItem('employment', index);
	}

	removePet(index) {
		this.removeFormGroupItem('pets', index);
	}

	removeVetPetOnFile(index) {
		this.removeFormGroupItem('vetPetsOnFile', index);
	}

	removeFormGroupItem(control: string, index: number) {
		(this.applicationForm.controls[control] as FormArray).removeAt(index);
	}

	createAdult() {
		return this.createFormGroup({
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
		});
	}

	createEmployment() {
		return this.createFormGroup({
			applicant: ['', [Validators.required]], 
			employmentType: ['', [Validators.required]], 
			employer: ['', []],
			occupation: ['', []],
			employmentExplain: ['', []]
		});
	}
	
	createChild() {
		return this.createFormGroup({
			age: ['', [Validators.required, Validators.min(0)]],
		});
	}

	createPhoneNumber() {
		return this.createFormGroup({
			number: ['', [Validators.required]],
			type: ['', [Validators.required]],
		});
	}
	
	createPet() {
		return this.createFormGroup({
			type: ['', [Validators.required]], 
			breed: ['', [Validators.required]],
			name: ['', []],
			age: ['', []],
		});
	}

	createVetPetOnFile() {
		return this.createFormGroup({
			name: ['', [Validators.required]],
		});
	}

	createFormGroup(props: { [key: string]: Array<string | Array<Validators>> }) {
		return this.fb.group(props);
	}

	onChangeNumberChildren(e) {
		this.applicationForm.setControl('childrenAges', this.fb.array([]));

		const numberChildren = parseInt(e.target.value);
		for (let i = 0; i < numberChildren; i++) {
			this.addChild();
		}
	}

	submit() {
		console.log("valid: ", this.applicationForm.valid);

		this.errors = [];
		this.submitError = false;
		this.missingCaptcha = false;

		if (this.applicationForm.invalid) {
			Object.keys(this.applicationForm.controls).forEach(key => {
				if (!this.applicationForm.controls[key].valid) {
					this.errors.push({
						field: key,
						message: this.errorLabels[key]
					});
				}
			});
		}

		if (!this.captcha) {
			this.missingCaptcha = true;
		}

		const application = new Application({
			...this.applicationForm.value,
			captcha: this.captcha,
			token: this.token,
		});

		console.log(application);

		if (this.applicationForm.valid && this.captcha && !this.errors.length) {
			this.submitted = true;
			this.recaptchaRef.reset();

			this.http.post('./assets/application-submit.php', application)
			.subscribe({
				next: data => {
					// console.log(data);
					this.response = data;
					this.submitSuccess = this.response.success;
					this.applicationNumber = this.response.applicationNumber;

					if (this.submitSuccess) {
						this.showApplication = false;
					} else {
						this.submitError = true;
						this.errors.push(this.response.message);
					}
				},
    			error: error => {
					console.error('Error submitting application!', error);
					this.submitError = true;
					this.errors.push(error);
				}
			});
		}
	}

	onRecaptchaResolved(response:string) {
		this.captcha = response;
	}
}
