import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fancy-img',
  templateUrl: './fancy-img.component.html',
  styleUrls: ['./fancy-img.component.scss']
})
export class FancyImgComponent implements OnInit {
  @Input() backgroundImg:string;
  @Input() parallax:Boolean = true;
  @Input() backgroundAttachment:string = 'fixed';
  @Input() backgroundPosition:string = 'center';
  @Input() darkenBackground:Boolean = false;
  @Input() margin:Boolean = false;

  constructor() { }

  ngOnInit(): void {
	  this.backgroundAttachment = this.parallax ? "fixed" : "scroll";
  }

}
