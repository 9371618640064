import { Injectable } from '@angular/core';
import { Client } from "@petfinder/petfinder-js";

@Injectable({
  providedIn: 'root'
})
export class AdoptableDogsService {

  constructor() { }

  get() {
	const client = new Client({apiKey: "fSpu6vknPujc7xsMtq2bVi6UEpvmAu6kdHJnuxMQtn9XuLV8Lt", secret: "KwCYuwmaeDH1kkuClyR1CKLSQCeKrwAbVzVJRsAJ"});
	return client.animal.search({organization: "OH1189"})
		.then(function (response) {
			// console.log("RESPONSE:", response.data.animals);
			return response.data.animals;
		})
		.catch(function (error) {
			// Handle the error
			console.log("ERROR:", error.details);
			return false;
		});
  }
}
