<div class="overlay" *ngIf="submitted && !(submitSuccess || submitError)">
	<mat-spinner color="accent" aria-label="submitting application form"></mat-spinner>
</div>
<div *ngIf="showApplication" [ngClass]="{ application: true, blur: submitted && !(submitSuccess || submitError), errors: errors.length }">
	<app-error-list [errors]="errors"></app-error-list>
	<form [formGroup]="applicationForm" (ngSubmit)="submit()" class="application-form">
		<div class="form-item">
			<app-anchor-links [ids]="['confirmAtLeast18YearsOfAge', 'confirmHaveConsentOfAddAdultsInHousehold', 'confirmUnderstandRightToDenyOrAcceptApplication']"></app-anchor-links>
			<p>In order to be considered for an adoption, you must:</p>
			<ul>
				<li [ngClass]="{ 'error': submitted && applicationForm.value['confirmAtLeast18YearsOfAge'] !== true }">
					<mat-checkbox formControlName="confirmAtLeast18YearsOfAge">Be at least 25 years of age</mat-checkbox>
				</li>
				<li [ngClass]="{ 'error': submitted && applicationForm.value['confirmHaveConsentOfAddAdultsInHousehold'] !== true }">
					<mat-checkbox formControlName="confirmHaveConsentOfAddAdultsInHousehold">Have the consent of all adults living in the household</mat-checkbox>
				</li>
				<li [ngClass]="{ 'error': submitted && applicationForm.value['confirmUnderstandRightToDenyOrAcceptApplication'] !== true }">
					<mat-checkbox formControlName="confirmUnderstandRightToDenyOrAcceptApplication">Understand that we have the right to deny or accept any application for any reason</mat-checkbox>
				</li>
			</ul>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['adultsApplyingForAdoption']"></app-anchor-links>
			<p>Names of adults applying for adoption:</p>
			<ul formArrayName="adultsApplyingForAdoption"
				*ngFor="let item of getAdultsApplyingForAdoption(); let i = index">
				<li [formGroupName]="i">
					<mat-form-field appearance="outline">
						<mat-label>First Name</mat-label>
						<input matInput formControlName="firstName" placeholder="First name" maxlength="45">
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Last Name</mat-label>
						<input matInput formControlName="lastName" placeholder="Last name" maxlength="45">
					</mat-form-field>
					<button type="button" mat-mini-fab color="accent" aria-label="Remove adult applying for adoption" (click)="removeAdultApplyingForAdoption(i)" *ngIf="getAdultsApplyingForAdoption().length > 1"><mat-icon>remove</mat-icon></button>
				</li>
			</ul>
			<button type="button" mat-raised-button color="primary" (click)="addAdultApplyingForAdoption()"><mat-icon>add</mat-icon> Add Applicant</button>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['country', 'address', 'city', 'state', 'zip']"></app-anchor-links>
			<p>Address</p>
			<mat-form-field appearance="outline">
				<mat-label>Country</mat-label>
				<mat-select formControlName="country">
					<mat-option *ngFor="let country of countries" [value]="country">
						{{country}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Address</mat-label>
				<input matInput formControlName="address"  maxlength="100">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>City</mat-label>
				<input matInput formControlName="city" maxlength="45">
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="applicationForm.value['country'] === 'United States'">
				<mat-label>State</mat-label>
				<mat-select formControlName="state">
					<mat-option *ngFor="let state of states" [value]="state.abbreviation">
						{{state.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="applicationForm.value['country'] === 'Canada'">
				<mat-label>Province/Territory</mat-label>
				<mat-select formControlName="state">
					<mat-option *ngFor="let province of provinces" [value]="province.abbreviation">
						{{province.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="applicationForm.value['country'] === 'United States'">
				<mat-label>ZIP Code</mat-label>
				<input matInput formControlName="zip" pattern="\d{5}(-\d{4})?">
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="applicationForm.value['country'] === 'Canada'">
				<mat-label>Postal Code</mat-label>
				<input matInput formControlName="zip" maxlength="20">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['phoneNumbers']"></app-anchor-links>
			<p>Phone Number</p>
			<ul formArrayName="phoneNumbers"
				*ngFor="let phoneNumber of getPhoneNumbers(); let i = index">
				<li [formGroupName]="i">
					<mat-form-field appearance="outline">
						<mat-label>Number</mat-label>
						<input matInput formControlName="number" type="tel" pattern="(.*[0-9].*){10}">
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Type</mat-label>
						<mat-select formControlName="type">
							<mat-option *ngFor="let phoneType of phoneTypes" [value]="phoneType">
								{{phoneType}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button type="button" mat-mini-fab color="accent" aria-label="Remove phone number" (click)="removePhoneNumber(i)" *ngIf="getPhoneNumbers().length > 1"><mat-icon>remove</mat-icon></button>
				</li>
			</ul>
			<button type="button" mat-raised-button color="primary" (click)="addPhoneNumber()"><mat-icon>add</mat-icon> Add Phone Number</button>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['email']"></app-anchor-links>
			<mat-form-field appearance="outline">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" type="email" maxlength="45">
			</mat-form-field>
		</div>
		
		<div class="form-item">
			<app-anchor-links [ids]="['applicantIntroduction']"></app-anchor-links>
			<p>Tell us more about yourself (interests/sports/hobbies/lifestyle)</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="applicantIntroduction"
					maxlength="5000">
				</textarea>
			</mat-form-field>
		</div>

		<div class="form-item">
			<app-anchor-links [ids]="['numberAdultsInHousehold']"></app-anchor-links>
			<p>Number of adults in household?</p>
			<mat-form-field appearance="outline">
				<mat-label>Adults</mat-label>
				<input matInput formControlName="numberAdultsInHousehold" type="number" min="1" max="10">
				<!-- <input type="text" inputmode="numeric" pattern="[0-9]*"> -->
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['numberChildren', 'childrenAges']"></app-anchor-links>
			<p>Number of children in household?</p>
			<mat-form-field appearance="outline">
				<mat-label>Children</mat-label>
				<input matInput formControlName="numberChildren" type="number" min="0" max="20" (keyup)="onChangeNumberChildren($event)" (change)="onChangeNumberChildren($event)">
			</mat-form-field>
			<ul formArrayName="childrenAges">
				<li *ngFor="let item of getChildrenAges(); let i = index" [formGroupName]="i">
					<mat-form-field appearance="outline">
						<mat-label>Age</mat-label>
						<input matInput formControlName="age" type="number" min="0" max="99">
					</mat-form-field>
				</li>
			</ul>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['numberChildren'] > 0">
			<app-anchor-links [ids]="['childrenDogExperience']"></app-anchor-links>
			<p>Have the children in the home lived/interacted with dogs before?</p>
			<mat-radio-group formControlName="childrenDogExperience" [ngClass]="{ 'error': submitted && !applicationForm.value['childrenDogExperience'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['employment']"></app-anchor-links>
			<p>Employer(s)/Occupation(s):</p>
			<ul formArrayName="employment"
				*ngFor="let item of getEmployment(); let i = index">
				<li [formGroupName]="i">
					<mat-form-field appearance="outline">
						<mat-label>Applicant</mat-label>
						<mat-select formControlName="applicant">
							<mat-option *ngFor="let applicant of applicationForm.value['adultsApplyingForAdoption']" [value]="applicant.firstName + ' ' + applicant.lastName">
								{{applicant.firstName}} {{applicant.lastName}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Employment Type</mat-label>
						<mat-select formControlName="employmentType">
							<mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">
								{{employmentType}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="item.value['employmentType'] === 'Employed Full-Time' || item.value['employmentType'] === 'Employed Part-Time'">
						<mat-label>Employer</mat-label>
						<input matInput formControlName="employer" placeholder="Employer" maxlength="45">
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="item.value['employmentType'] === 'Employed Full-Time' || item.value['employmentType'] === 'Employed Part-Time'">
						<mat-label>Occupation</mat-label>
						<input matInput formControlName="occupation" placeholder="Occupation" maxlength="45">
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="item.value['employmentType'] === 'Other'">
						<mat-label>Explain</mat-label>
						<input matInput formControlName="employmentExplain" maxlength="100">
					</mat-form-field>
					<button type="button" mat-mini-fab color="accent" aria-label="Remove employment" (click)="removeEmployment(i)" *ngIf="getEmployment().length > 1"><mat-icon>remove</mat-icon></button>
				</li>
			</ul>
			<button type="button" mat-raised-button color="primary" (click)="addEmployment()"><mat-icon>add</mat-icon> Add Employment</button>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['rentOrOwn']"></app-anchor-links>
			<p>Do you rent or own your home?</p>
			<mat-radio-group formControlName="rentOrOwn" [ngClass]="{ 'error': submitted && !applicationForm.value['rentOrOwn'] }">
				<mat-radio-button value="Rent">Rent</mat-radio-button>
				<mat-radio-button value="Own">Own</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['homeType', 'homeTypeDescribe']"></app-anchor-links>
			<p>Do you live in a:</p>
			<mat-form-field appearance="outline">
				<mat-label>Home Type</mat-label>
				<mat-select formControlName="homeType">
					<mat-option *ngFor="let homeType of homeTypes" [value]="homeType">
						{{homeType}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="applicationForm.value['homeType'] === 'Other'">
				<mat-label>Describe:</mat-label>
				<input matInput formControlName="homeTypeDescribe" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['rentOrOwn'] === 'Rent'">
			<app-anchor-links [ids]="['landlordName', 'landlordPhone', 'landlordEmail']"></app-anchor-links>
			<p>If you rent, please give landlord’s name, phone, and email:</p>
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="landlordName" maxlength="45">
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Phone</mat-label>
				<input matInput formControlName="landlordPhone" type="tel" pattern="(.*[0-9].*){10}">
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Email</mat-label>
				<input matInput formControlName="landlordEmail" type="email" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['durationLivedAtAddressYears', 'durationLivedAtAddressMonths']"></app-anchor-links>
			<p>How long have you lived at your current address?</p>
			<mat-form-field appearance="outline">
				<mat-label>Years</mat-label>
				<input matInput formControlName="durationLivedAtAddressYears" type="number" min="0" max="99">
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Months</mat-label>
				<input matInput formControlName="durationLivedAtAddressMonths" type="number" min="0" max="12">
			</mat-form-field>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['durationLivedAtAddressYears'] < 2">
			<app-anchor-links [ids]="['previousAddress']"></app-anchor-links>
			<p>If less than 2 years, previous address:</p>
			<mat-form-field appearance="outline">
				<mat-label>Previous address</mat-label>
				<input matInput formControlName="previousAddress" maxlength="100">
			</mat-form-field>
		</div>
		<h2>Tell us some things you are looking for in a dog</h2>
		<div class="form-item">
			<app-anchor-links [ids]="['preferredAge']"></app-anchor-links>
			<p>Age preferred:</p>
			<ul>
				<li *ngFor="let age of ages">
					<mat-checkbox (change)="onCheckChange($event, 'preferredAge')" [name]="age" [checked]="dataContains('preferredAge', age)" [ngClass]="{ 'error': submitted && !applicationForm.value['preferredAge'].length }">
						{{age}}
					</mat-checkbox>
				</li>
			</ul>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['preferredSex']"></app-anchor-links>
			<p>Do you prefer:</p>
			<mat-radio-group formControlName="preferredSex" [ngClass]="{ 'error': submitted && !applicationForm.value['preferredSex'] }">
				<mat-radio-button value="Male">Male</mat-radio-button>
				<mat-radio-button value="Female">Female</mat-radio-button>
				<mat-radio-button value="No Preference">No Preference</mat-radio-button>
			</mat-radio-group>
		</div>
		
		<div class="form-item">
			<app-anchor-links [ids]="['preferredActivityLevel']"></app-anchor-links>
			<p>Do you prefer a dog who is:</p>
			<ul>
				<li *ngFor="let activityLevel of activityLevels">
					<mat-checkbox (change)="onCheckChange($event, 'preferredActivityLevel')" [name]="activityLevel" [checked]="dataContains('preferredActivityLevel', activityLevel)" [ngClass]="{ 'error': submitted && !applicationForm.value['preferredActivityLevel'].length }">
						{{activityLevel}}
					</mat-checkbox>
				</li>
			</ul>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['importantConsiderations', 'importantConsiderationsOther']"></app-anchor-links>
			<p>Which of these is important:</p>
			<ul>
				<li *ngFor="let item of important">
					<mat-checkbox (change)="onCheckChange($event, 'importantConsiderations')" [name]="item" [checked]="dataContains('importantConsiderations', item)">
						{{item}}
					</mat-checkbox>
				</li>
			</ul>
			<mat-form-field appearance="outline" *ngIf="applicationForm.value['importantConsiderations'].indexOf('Other') > -1">
				<mat-label>Other:</mat-label>
				<input matInput formControlName="importantConsiderationsOther" maxlength="100">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['reasonForAdopting']"></app-anchor-links>
			<p>Why have you decided to adopt a cattle dog?</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="reasonForAdopting"
					maxlength="5000">
				</textarea>
			</mat-form-field> 
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['dogApplyingFor']"></app-anchor-links>
			<p>Which dog are you applying for?</p>
			<mat-form-field appearance="outline">
				<input matInput formControlName="dogApplyingFor" maxlength="100">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['dogApplyingForExplain']"></app-anchor-links>
			<p>Why do you feel this dog would be a good match for you?</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="dogApplyingForExplain"
					maxlength="5000">
				</textarea>
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['suggestOtherDogsOkay']"></app-anchor-links>
			<p>If we feel that another dog in our foster home program might be a better match, are you willing to accept suggestions?</p>
			<mat-radio-group formControlName="suggestOtherDogsOkay" [ngClass]="{ 'error': submitted && !applicationForm.value['suggestOtherDogsOkay'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['herdingBreedExperience']"></app-anchor-links>
			<p>Have you owned an ACD or other herding breed?</p>
			<mat-radio-group formControlName="herdingBreedExperience" [ngClass]="{ 'error': submitted && !applicationForm.value['herdingBreedExperience'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['herdingBreedExperience'] === 'No'">
			<app-anchor-links [ids]="['breedKnowledge']"></app-anchor-links>
			<p>If you have not owned an ACD, tell us what you know about the breed:</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="breedKnowledge"
					maxlength="5000">
				</textarea>
			</mat-form-field> 
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['behaviorConcerns']"></app-anchor-links>
			<p>What behaviors would you not be comfortable dealing with?</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="behaviorConcerns"
					maxlength="5000">
				</textarea>
			</mat-form-field> 
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['hasPets']"></app-anchor-links>
			<p>Do you currently have any pets?</p>
			<mat-radio-group formControlName="hasPets" (change)="setPets()" [ngClass]="{ 'error': submitted && !applicationForm.value['hasPets'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['hasPets'] === 'No'">
			<app-anchor-links [ids]="['pastPets']"></app-anchor-links>
			<p>Have you had pets in the past?</p>
			<mat-radio-group formControlName="pastPets" (change)="setPets()" [ngClass]="{ 'error': submitted && !applicationForm.value['pastPets'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="anyPets">
			<app-anchor-links [ids]="['pets']"></app-anchor-links>
			<p *ngIf="applicationForm.value['hasPets'] === 'Yes'">Please list type, breed, name and age of pets:</p>
			<p *ngIf="applicationForm.value['hasPets'] === 'No' && applicationForm.value['pastPets'] === 'Yes'">Please list type and breed of pets:</p>
			<ul formArrayName="pets"
				*ngFor="let item of getPets(); let i = index">
				<li [formGroupName]="i">
					<mat-form-field appearance="outline">
						<mat-label>Type</mat-label>
						<mat-select formControlName="type" (selectionChange)="setDogExperience()">
							<mat-option *ngFor="let petType of petTypes" [value]="petType">
								{{petType}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Breed</mat-label>
						<input matInput formControlName="breed" maxlength="45">
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="applicationForm.value['hasPets'] === 'Yes'">
						<mat-label>Name</mat-label>
						<input matInput formControlName="name" maxlength="45">
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="applicationForm.value['hasPets'] === 'Yes'">
						<mat-label>Age (years)</mat-label>
						<input matInput formControlName="age" type="number">
					</mat-form-field>
					<button type="button" mat-mini-fab color="accent" aria-label="Remove pet" (click)="removePet(i)" *ngIf="getPets().length > 0"><mat-icon>remove</mat-icon></button>
				</li>
			</ul>
			<button type="button" mat-raised-button color="primary" (click)="addPet()"><mat-icon>add</mat-icon> Add Pet</button>
		</div>
		<ng-container *ngIf="dogExperience">
			<div class="form-item">
				<app-anchor-links [ids]="['heartwormPrevention']"></app-anchor-links>
				<p *ngIf="applicationForm.value['hasPets'] === 'Yes'">Is your dog on monthly heartworm prevention year round? (Please also answer for any previous dogs)</p>
				<p *ngIf="applicationForm.value['hasPets'] === 'No' && applicationForm.value['pastPets'] === 'Yes'">Was your dog on monthly heartworm prevention year round? (Please answer for any previous dogs)</p>
				<mat-radio-group formControlName="heartwormPrevention" [ngClass]="{ 'error': submitted && !applicationForm.value['heartwormPrevention'] }">
					<mat-radio-button value="Yes">Yes</mat-radio-button>
					<mat-radio-button value="No">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div class="form-item" *ngIf="applicationForm.value['heartwormPrevention'] === 'No'">
				<app-anchor-links [ids]="['heartwormPreventionExplain']"></app-anchor-links>
				<mat-form-field appearance="outline">
					<mat-label>Explain</mat-label>
					<input matInput formControlName="heartwormPreventionExplain" maxlength="100">
				</mat-form-field>
			</div>
			<div class="form-item" *ngIf="applicationForm.value['heartwormPrevention'] === 'Yes'">
				<app-anchor-links [ids]="['heartwormPreventionBrand']"></app-anchor-links>
				<p>What brand of prevention do you use?</p>
				<mat-form-field appearance="outline">
					<mat-label>Brand</mat-label>
					<input matInput formControlName="heartwormPreventionBrand" maxlength="20">
				</mat-form-field>
			</div>
			<div class="form-item">
				<app-anchor-links [ids]="['fleaTickPrevention']"></app-anchor-links>
				<p>Is your dog on monthly flea/tick prevention? (Please also answer for any previous dogs if you do not currently have a dog)</p>
				<mat-radio-group formControlName="fleaTickPrevention" [ngClass]="{ 'error': submitted && !applicationForm.value['fleaTickPrevention'] }">
					<mat-radio-button value="Yes">Yes</mat-radio-button>
					<mat-radio-button value="No">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div class="form-item" *ngIf="applicationForm.value['fleaTickPrevention'] === 'No'">
				<app-anchor-links [ids]="['fleaTickPreventionExplain']"></app-anchor-links>
				<mat-form-field appearance="outline">
					<mat-label>Explain</mat-label>
					<input matInput formControlName="fleaTickPreventionExplain" maxlength="100">
				</mat-form-field>
			</div>
			<div class="form-item" *ngIf="applicationForm.value['fleaTickPrevention'] === 'Yes'">
				<app-anchor-links [ids]="['fleaTickPreventionBrand']"></app-anchor-links>
				<p>What brand of prevention do you use?</p>
				<mat-form-field appearance="outline">
					<mat-label>Brand</mat-label>
					<input matInput formControlName="fleaTickPreventionBrand"  maxlength="20">
				</mat-form-field>
			</div>
			<div class="form-item">
				<app-anchor-links [ids]="['yearlyTesting']"></app-anchor-links>
				<p>Is your dog tested yearly for heartworm and flea/tick-borne diseases? (Please also answer for any previous dogs)</p>
				<mat-radio-group formControlName="yearlyTesting" [ngClass]="{ 'error': submitted && !applicationForm.value['yearlyTesting'] }">
					<mat-radio-button value="Yes">Yes</mat-radio-button>
					<mat-radio-button value="No">No</mat-radio-button>
				</mat-radio-group>
			</div>
		</ng-container>
		<div class="form-item" *ngIf="applicationForm.value['hasPets'] === 'No'">
			<app-anchor-links [ids]="['petExperience']"></app-anchor-links>
			<p>If you do NOT currently have pets, please briefly explain the dog breeds you’ve had experience with and when</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="petExperience"
					maxlength="5000">
				</textarea>
			</mat-form-field> 
		</div>
		<div class="form-item" *ngIf="anyPets">
			<app-anchor-links [ids]="['allPetsYearlyVetVisitAndVaccinesCurrent']"></app-anchor-links>
			<p>Are ALL your pets seen at least yearly by a veterinarian and kept up to date on all vaccines?</p>
			<mat-radio-group formControlName="allPetsYearlyVetVisitAndVaccinesCurrent" [ngClass]="{ 'error': submitted && !applicationForm.value['allPetsYearlyVetVisitAndVaccinesCurrent'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['allPetsYearlyVetVisitAndVaccinesCurrent'] === 'No'">
			<app-anchor-links [ids]="['allPetsYearlyVetVisitAndVaccinesCurrentExplain']"></app-anchor-links>
			<mat-form-field appearance="outline">
				<mat-label>Explain</mat-label>
				<input matInput formControlName="allPetsYearlyVetVisitAndVaccinesCurrentExplain"  maxlength="100">
			</mat-form-field>
		</div>
		<div class="form-item" *ngIf="anyPets">
			<app-anchor-links [ids]="['allPetsSpayedNeutered']"></app-anchor-links>
			<p>Are all pets in your home spayed or neutered?</p>
			<mat-radio-group formControlName="allPetsSpayedNeutered" [ngClass]="{ 'error': submitted && !applicationForm.value['allPetsSpayedNeutered'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['allPetsSpayedNeutered'] === 'No'">
			<app-anchor-links [ids]="['allPetsSpayedNeuteredExplain']"></app-anchor-links>
			<mat-form-field appearance="outline">
				<mat-label>Explain</mat-label>
				<input matInput formControlName="allPetsSpayedNeuteredExplain" maxlength="100">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['trainingPlan']"></app-anchor-links>
			<p>BACDR requires all adopters to attend a group puppy or basic obedience class after adoption. Are you willing to do this?</p>
			<mat-radio-group formControlName="trainingPlan" [ngClass]="{ 'error': submitted && !applicationForm.value['trainingPlan'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['trainingPlan'] === 'No'">
			<app-anchor-links [ids]="['trainingPlanExplain']"></app-anchor-links>
			<mat-form-field appearance="outline">
				<mat-label>Explain</mat-label>
				<input matInput formControlName="trainingPlanExplain" maxlength="255">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['awareLicenseLeashAnimalLaws']"></app-anchor-links>
			<p>Are you aware of the license, leash and animal laws of your community?</p>
			<mat-radio-group formControlName="awareLicenseLeashAnimalLaws" [ngClass]="{ 'error': submitted && !applicationForm.value['awareLicenseLeashAnimalLaws'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['fencedYard']"></app-anchor-links>
			<p>Do you have a fenced yard?</p>
			<mat-radio-group formControlName="fencedYard" [ngClass]="{ 'error': submitted && !applicationForm.value['fencedYard'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['fencedYard'] === 'Yes'">
			<app-anchor-links [ids]="['fencedYardDescribe']"></app-anchor-links>
			<p>If yes, describe it (material and height)</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea matInput formControlName="fencedYardDescribe" maxlength="255"></textarea>
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['exerciseBathroomNeedsDescribe']"></app-anchor-links>
			<p>How will you handle the dog’s exercise and bathroom needs?</p>
			<mat-form-field appearance="outline" class="wide">
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="exerciseBathroomNeedsDescribe"
					maxlength="5000">
				</textarea>
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['poolOrPond']"></app-anchor-links>
			<p>Do you have a swimming pool or pond on your property?</p>
			<mat-radio-group formControlName="poolOrPond" [ngClass]="{ 'error': submitted && !applicationForm.value['poolOrPond'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['poolOrPond'] === 'Yes'">
			<app-anchor-links [ids]="['poolOrPondDescribe']"></app-anchor-links>
			<mat-form-field appearance="outline">
				<mat-label>Describe</mat-label>
				<input matInput formControlName="poolOrPondDescribe" maxlength="255">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['aloneHours']"></app-anchor-links>
			<p>How many hours a day will dog be home alone?</p>
			<mat-form-field appearance="outline">
				<mat-label>Hours</mat-label>
				<input matInput formControlName="aloneHours" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['aloneLocation']"></app-anchor-links>
			<p>Where will dog be at this time?</p>
			<mat-form-field appearance="outline">
				<mat-label>Location</mat-label>
				<input matInput formControlName="aloneLocation" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['ownCrate']"></app-anchor-links>
			<p>Do you own a crate and know how to use it?</p>
			<mat-radio-group formControlName="ownCrate" [ngClass]="{ 'error': submitted && !applicationForm.value['ownCrate'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['sleepLocation']"></app-anchor-links>
			<p>Where will the dog sleep at night?</p>
			<mat-form-field appearance="outline">
				<mat-label>Location</mat-label>
				<input matInput formControlName="sleepLocation" maxlength="45">
			</mat-form-field>
		</div>
		<h2>Please note that ALL dogs adopted through BACDR are intended to be kept as INDOOR DOGS, not tethered, housed or kept for long periods of time outside regardless of the weather.</h2>
		<div class="form-item">
			<app-anchor-links [ids]="['vacationCare']"></app-anchor-links>
			<p>Who would take care of dog when you go on vacation, etc?</p>
			<mat-form-field appearance="outline">
				<input matInput formControlName="vacationCare" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['allergies']"></app-anchor-links>
			<p>Does anyone in your home have allergies to dogs?</p>
			<mat-radio-group formControlName="allergies" [ngClass]="{ 'error': submitted && !applicationForm.value['allergies'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['responsibleParty']"></app-anchor-links>
			<p>Which household member will be responsible for the care, training, exercise and feeding needs of your dog?</p>
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="responsibleParty" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['returnedAnimal']"></app-anchor-links>
			<p>Have you ever given up or returned an animal?</p>
			<mat-radio-group formControlName="returnedAnimal" [ngClass]="{ 'error': submitted && !applicationForm.value['returnedAnimal'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['returnedAnimal'] === 'Yes'">
			<app-anchor-links [ids]="['returnedAnimalExplain']"></app-anchor-links>
			<mat-form-field appearance="outline" class="wide">
				<mat-label>Explain</mat-label>
				<textarea
					matInput
					cdkTextareaAutosize
					cdkAutosizeMinRows="3"
					cdkAutosizeMaxRows="10"
					formControlName="returnedAnimalExplain"
					maxlength="5000">
				</textarea>
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['homeVisitOkay']"></app-anchor-links>
			<p>Are you willing to let us or one of our representatives do a home visit? (this is for us to get an idea of the environment the dog would be in and to suggest any possible changes that may make the dog’s transition easier)</p>
			<mat-radio-group formControlName="homeVisitOkay" [ngClass]="{ 'error': submitted && !applicationForm.value['homeVisitOkay'] }">
				<mat-radio-button value="Yes">Yes</mat-radio-button>
				<mat-radio-button value="No">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['vetName', 'vetPhone']"></app-anchor-links>
			<p>Name of Veterinarian or Clinic</p>
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="vetName" placeholder="Vet/Clinic Name" maxlength="45">
			</mat-form-field>
			<p>Veterinarian or Clinic Phone Number</p>
			<mat-form-field appearance="outline">
				<mat-label>Phone Number</mat-label>
				<input matInput formControlName="vetPhone" placeholder="Vet/Clinic Phone" type="tel">
			</mat-form-field>
		</div>
		<h3>Please call your veterinarian’s office to give permission for them to release info to us when we call them.</h3>
		<h2 class="uppercase">When calling your veterinarian, we will be looking to ensure that your current and previous pets are/were kept up to date on appropriate vaccinations, heartworm testing, and that they have been kept on year-round heartworm preventative.</h2>
		<div class="form-item">
			<app-anchor-links [ids]="['vetOwnerOnFile']"></app-anchor-links>
			<p>What owner name is on file with veterinarian’s office?</p>
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="vetOwnerOnFile" maxlength="45">
			</mat-form-field>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['vetPetsOnFile']"></app-anchor-links>
			<p>List pet names on file there:</p>
			<ul formArrayName="vetPetsOnFile"
				*ngFor="let item of getVetPetsOnFile(); let i = index">
				<li [formGroupName]="i">
					<mat-form-field appearance="outline">
						<mat-label>Name</mat-label>
						<input matInput formControlName="name" maxlength="45">
					</mat-form-field>
					<button type="button" mat-mini-fab color="accent" aria-label="Remove pet on file" (click)="removeVetPetOnFile(i)"><mat-icon>remove</mat-icon></button>
				</li>
			</ul>
			<button type="button" mat-raised-button color="primary" (click)="addVetPetOnFile()"><mat-icon>add</mat-icon> Add Pet</button>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['hasPets'] === 'No' && applicationForm.value['pastPets'] === 'Yes'">
			<app-anchor-links [ids]="['vetPreviousPetsName', 'vetPreviousPetsPhone']"></app-anchor-links>
			<p>If no current pets, please list veterinarian used for any previous pets and the pets’ names</p>
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="vetPreviousPetsName" maxlength="45">
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Phone</mat-label>
				<input matInput formControlName="vetPreviousPetsPhone" type="tel" pattern="(.*[0-9].*){10}">
			</mat-form-field>
		</div>
		<div class="form-item" *ngIf="applicationForm.value['hasPets'] === 'No' && applicationForm.value['pastPets'] === 'No'">
			<app-anchor-links [ids]="['vetNoPetsName', 'vetNoPetsPhone']"></app-anchor-links>
			<p>If you have not had a pet, please list veterinarian you plan on using</p>
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput formControlName="vetNoPetsName" maxlength="45">
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Phone</mat-label>
				<input matInput formControlName="vetNoPetsPhone" type="tel" pattern="(.*[0-9].*){10}">
			</mat-form-field>
		</div>
		<div class="form-item" [ngClass]="{ 'error': submitted && applicationForm.value['certify'] !== true }">
			<app-anchor-links [ids]="['certify']"></app-anchor-links>
			<mat-checkbox formControlName="certify">By signing below, I certify that the information I have given is true and I realize that any misrepresentation of the facts may result in my losing the privilege of adoption a pet from Buckeye Australian Cattle Dog Rescue (BACDR).   I understand that BACDR has the right to deny my request to adopt a dog for any situation that would be against our adoption policies, in violation of any state or local ordinance, or not in the best interest of the animal.   I authorize the investigation of all statements in this application.  I also authorize my veterinarian to release any information needed by BACDR to make a determination in this adoption.</mat-checkbox>
		</div>
		<div class="form-item">
			<app-anchor-links [ids]="['signature']"></app-anchor-links>
			<mat-form-field appearance="outline">
				<mat-label>Signature</mat-label>
				<input matInput formControlName="signature" maxlength="45">
			</mat-form-field>
		</div>
		<div class="recaptcha">
			<re-captcha #recaptchaRef (resolved)="onRecaptchaResolved($event)" siteKey="6Lc-U78ZAAAAAHIMpV4Li5cJrjN8KhfKrkyb-gZE"></re-captcha>
			<div *ngIf="missingCaptcha" class="error">Please verify your humanity</div>
		</div>
		<div class="form-item">
			<mat-checkbox formControlName="sendEmail">Send me a copy of my application</mat-checkbox>
		</div>
		<h2>All information contained in this application will remain confidential and the property of BACDR.</h2>
		<app-error-list [errors]="errors"></app-error-list>
		<div class="submitButton">
			<button type="submit" mat-raised-button color="accent">Submit</button>
		</div>
	</form>
</div>
<div *ngIf="submitSuccess" class="message">
	<h2>Application #{{applicationNumber}} submitted!</h2>
	<p>Thank you for submitting an application to Buckeye Australian Cattle Dog Rescue. We will review your application as soon as possible. If you have any questions or concerns, please contact us using the contact form at <a href="https://www.buckeyeacdrescue.org/#contact">buckeyeacdrescue.org</a> or by email at <a href="mailto:buckeyeacdrescue@gmail.com">buckeyeacdrescue@gmail.com</a>.</p>
</div>
<div *ngIf="formError" class="message">
	<h2>Error</h2>
	<p>{{formError}}</p>
</div>