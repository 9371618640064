<div class="adoptable-dogs">
	<h2>Current Adoptable Dogs</h2>
	<div *ngIf="loading" class="loader">Loading...</div>
	<div *ngIf="!loading" class="pet-list">
		<div *ngIf="pets.length" class="grid">
			<div *ngFor="let pet of pets" class="grid-item">
				<a href="{{pet.url}}" target="_blank" class="pet-card">
					<div class="photo" style="background-image: url({{pet.primary_photo_cropped.medium}});">
						<img src="{{pet.primary_photo_cropped.medium}}" alt="{{pet.name}}" class="visually-hidden">
					</div>
					<div class="body">
						<div class="details">
							<p class="name">{{pet.name}}</p>
							<ul class="info">
								<li *ngIf="pet.age">{{pet.age}}</li>
								<li *ngIf="pet.contact.address">{{pet.contact.address.city}}, {{pet.contact.address.state}}</li>
							</ul>
						</div>
					</div>
				</a>
			</div>
		</div>
		<div *ngIf="!error && !pets.length">
			<p>All of our available dogs are currently under evaluation or pending adoption. Please follow us on social media for updates and check back later for new adoptable dogs!</p>
		</div>
		<div *ngIf="error">
			<p>We have encountered an error. Please try again.</p>
		</div>
	</div>
</div>