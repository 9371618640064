import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import * as shajs from 'sha.js';
import { Application } from 'src/app/classes/application';

@Component({
	selector: 'app-review-application-page',
	templateUrl: './review-application-page.component.html',
	styleUrls: ['./review-application-page.component.scss']
})

export class ReviewApplicationPageComponent implements OnInit {

	@ViewChild('recaptchaRef')
	recaptchaRef: RecaptchaComponent;

	foundApplications: Array<Application> = [];
	applicationNumber = "";
	applicantName = "";
	applicantEmail = "";
	dogAppliedFor = "";
	applicationData = null;
	error = "";
	loading = false;
	
	authentication: FormGroup = new FormGroup({});
	captcha:string = "";
	submitted: Boolean = false;
	missingCaptcha:Boolean = false;
	response:any;
	submitSuccess:Boolean = false;
	submitError:Boolean = false;

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private http: HttpClient,
	) {}

	ngOnInit(): void {
		// this.applicationNumber = this.route.snapshot.paramMap.get('applicationNumber');
		this.route.queryParams.subscribe(params => {
			this.applicationNumber = params['application'];
		});

		this.authentication = this.fb.group({
			password: ['', [Validators.required]],
			applicationNumber: [this.applicationNumber, []],
			applicantName: [this.applicantName, []],
			applicantEmail: [this.applicantEmail, []],
			dogAppliedFor: [this.dogAppliedFor, []],
		});
	}

	loadApplication(applicationNumber) {
		this.submit(applicationNumber);
	}

	submit(applicationNumber?) {
		this.submitError = false;
		this.missingCaptcha = !this.captcha;

		const authData = {
			password: shajs('sha256').update(this.authentication.get('password').value).digest('hex'),
			captcha: this.captcha,
			applicationNumber: applicationNumber || this.authentication.get('applicationNumber').value?.toString(),
			name: this.authentication.get('applicantName').value?.toString() || this.applicantName,
			email: this.authentication.get('applicantEmail').value?.toString() || this.applicantEmail,
			dogAppliedFor: this.authentication.get('dogAppliedFor').value?.toString() || this.dogAppliedFor,
		};

		if (this.authentication.valid && !this.missingCaptcha) {
			this.submitted = true;
			this.recaptchaRef.reset();
			this.foundApplications = [];
			this.applicationData = null;
			this.loading = true;

			if (authData.applicationNumber) {
				this.http.post('./assets/retrieve-application.php', authData)
				.subscribe({
					next: (response: any) => {
						this.response = response.data;
						this.submitSuccess = response.success;

						if (this.submitSuccess) {
							this.renderApplication(this.response);
						} else {
							this.submitError = true;
							this.error = response.message;
						}
						this.loading = false;
					},
					error: error => {
						console.error('Error retrieving application!', error);
						this.submitError = true;
						this.error = error;
						this.loading = false;
					}
				});
			} else {
				this.http.post('./assets/application-find.php', authData)
				.subscribe({
					next: (response: any) => {
						this.response = response.data;
						this.submitSuccess = response.success;

						if (this.submitSuccess) {
							this.foundApplications = response.data.foundApplications;
						} else {
							this.submitError = true;
							this.error = response.message;
						}
						this.loading = false;
					},
					error: error => {
						console.error('Error retrieving application!', error);
						this.submitError = true;
						this.error = error;
						this.loading = false;
					}
				});
			}
		}
	}

	onRecaptchaResolved(response:string) {
		this.captcha = response;
	}

	renderApplication(data): void {
		this.applicationData = data;
	}

}
