<div>
	<h2>Follow Us</h2>
	<ul>
		<li>
			<a href="https://www.facebook.com/BuckeyeACDRescue/" target="_blank">Facebook</a>
		</li>
		<li>
			<a href="https://www.instagram.com/buckeyeacdrescue/" target="_blank">Instagram</a>
		</li>
	</ul>
</div>