import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-create-application-page',
  templateUrl: './create-application-page.component.html',
  styleUrls: ['./create-application-page.component.scss']
})
export class CreateApplicationPageComponent {
	email: string = "";
	emailError:Boolean = false;
	
	sendEmail: Boolean = true;

	submitted: Boolean = false;

	response;
	errors:Array<string> = [];

	submitError;

	constructor(
		private http: HttpClient
	) {}

	clearForm() {
		this.email = "";
		this.sendEmail = true;
		this.submitError = false;
		this.submitted = false;
	}

  	onSubmit(options?): void {
		this.response = null;
		this.errors = [];
		this.submitError = false;

		if (!this.email.trim()) {
			this.errors.push("Please enter recipient email");
			this.emailError = true;
		} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
			this.errors.push("Please enter a valid email");
			this.emailError = true;
		}

		if (!this.errors.length) {
			this.submitted = true;

			this.http.post('./assets/token-gen.php', {
				email: this.email.trim(),
				sendEmail: this.sendEmail,
				regenerateToken: !!options?.regenerateToken,
			}).subscribe({
				next: data => {
					console.log(data);
					this.response = data;

					if (this.response.url) {
						this.email = "";
					}
				},
				error: error => {
					console.error('Error creating application', error);
					this.submitError = `${error.message}${error.error?.text ? ': ' + error.error.text : ''}`;
				}
			});
		}
	}
}
