<div class="overlay" *ngIf="loading">
	<mat-spinner color="accent" aria-label="retrieving application"></mat-spinner>
</div>
<form [formGroup]="authentication" (ngSubmit)="submit()" class="authentication">
    <mat-form-field appearance="outline">
        <mat-label>Application Number</mat-label>
        <input matInput formControlName="applicationNumber" maxlength="45" type="number" min="0">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Applicant Name</mat-label>
        <input matInput formControlName="applicantName" maxlength="45" type="text">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Applicant Email</mat-label>
        <input matInput formControlName="applicantEmail" maxlength="45" type="text">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Dog Applied For</mat-label>
        <input matInput formControlName="dogAppliedFor" maxlength="45" type="text">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" maxlength="45" type="password">
    </mat-form-field>
    <div class="recaptcha">
        <re-captcha #recaptchaRef (resolved)="onRecaptchaResolved($event)" siteKey="6Lc-U78ZAAAAAHIMpV4Li5cJrjN8KhfKrkyb-gZE"></re-captcha>
        <div *ngIf="missingCaptcha" class="error">Please verify your humanity</div>
    </div>
    <div *ngIf="error" class="error">{{error}}</div>
    <div class="submitButton">
        <button type="submit" mat-raised-button color="accent">Submit</button>
    </div>
</form>
<div *ngIf="!applicationData && foundApplications.length">
    <table class="search-results">
        <thead>
            <tr>
                <td>Submitted</td>
                <td>Applicant Name</td>
                <td>Applicant Email</td>
                <td>Dog Applied For</td>
                <td>Application</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let application of foundApplications">
                <td>{{application.timestamp}}</td>
                <td>{{application.fullName}}</td>
                <td>{{application.email}}</td>
                <td>{{application.dogApplyingFor}}</td>
                <td class="app-button"><button (click)="loadApplication(application.applicationNumber)">{{application.applicationNumber}}</button></td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="applicationData">
    <app-application-pretty-print [application]="applicationData"></app-application-pretty-print>
</div>