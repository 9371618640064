<div class="application-pretty-print">
    <div class="form-item">
        <p>In order to be considered for an adoption, you must:</p>
        <ul>
            <li>
                <span *ngIf="application.confirmAtLeast18YearsOfAge">✅</span><span *ngIf="!application.confirmAtLeast18YearsOfAge">❌</span> Be at least 25 years of age
            </li>
            <li>
                <span *ngIf="application.confirmHaveConsentOfAddAdultsInHousehold">✅</span><span *ngIf="!application.confirmHaveConsentOfAddAdultsInHousehold">❌</span> Have the consent of all adults living in the household
            </li>
            <li>
                <span *ngIf="application.confirmUnderstandRightToDenyOrAcceptApplication">✅</span><span *ngIf="!application.confirmUnderstandRightToDenyOrAcceptApplication">❌</span> Understand that we have the right to deny or accept any application for any reason
            </li>
        </ul>
    </div>

    <div class="form-item">
        <p>Names of adults applying for adoption: <span>{{application.adultsApplyingForAdoption.join(", ")}}</span></p>
    </div>

    <div class="form-item flex half">
        <p>Address</p>
        <p>{{application.address}}<br>
        {{application.city}}, {{application.state}} {{application.zip}}<br>
        {{application.country}}</p>
    </div>

    <div class="form-item flex half">
        <p>Phone Numbers</p>
        <ul>
            <li *ngFor="let phoneNumber of application.phoneNumbers; let i = index">
                {{formatPhoneNumber(phoneNumber.number)}} ({{phoneNumber.type}})
            </li>
        </ul>
    </div>

    <div class="form-item">
        <p>Email: <span>{{application.email}}</span></p>
    </div>

    <div class="form-item">
        <p>Tell us more about yourself (interests/sports/hobbies/lifestyle)</p>
        <p>{{application.applicantIntroduction}}</p>
    </div>

    <div class="form-item">
        <p>Number of adults in household?: <span>{{application.numberAdultsInHousehold}}</span></p>
    </div>

    <div class="form-item">
        <p>Number of children in household?: <span>{{application.numberChildren}}<span *ngIf="application.childrenAges?.length"> ({{application.childrenAges.join(', ')}})</span></span></p>
    </div>

    <div class="form-item" *ngIf="application.numberChildren > 0">
        <p>Have the children in the home lived/interacted with dogs before?: <span *ngIf="application.childrenDogExperience">Yes</span><span *ngIf="!application.childrenDogExperience">No</span></p>
    </div>

    <div class="form-item">
        <p>Employment Info</p>
        <table>
            <thead>
                <tr>
                    <td>Applicant</td>
                    <td>Employment Type</td>
                    <td>Employer</td>
                    <td>Occupation</td>
                    <td *ngIf="employmentExplain">Explain</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let employment of application.employment">
                    <td>{{employment.name}}</td>
                    <td>{{employment.type}}</td>
                    <td>{{employment.employer}}</td>
                    <td>{{employment.occupation}}</td>
                    <td *ngIf="employmentExplain">{{employment.explanation}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="form-item">
        <p>Do you rent or own your home?: <span>{{application.rentOrOwn}}</span></p>
    </div>

    <div class="form-item">
        <p>Home Type: <span>{{application.homeType}}</span></p>
        <p *ngIf="application.homeTypeDescribe">Describe: <span>{{application.homeTypeDescribe}}</span></p>
    </div>

    <div class="form-item flex" *ngIf="application.rentOrOwn === 'Rent'">
        <p>If you rent, please give landlord’s name, phone, and email:</p>
        <div>
            <p>Name: <span>{{application.landlordName}}</span></p>
            <p>Phone: <span>{{application.landlordPhone}}</span></p>
            <p>Email: <span>{{application.landlordEmail}}</span></p>
        </div>
    </div>

    <div class="form-item">
        <p>How long have you lived at your current address?: <span>{{application.durationLivedAtAddressYears}} year(s) {{application.durationLivedAtAddressMonths}} month(s)</span></p>
    </div>

    <div class="form-item" *ngIf="application.durationLivedAtAddressYears < 2">
        <p>If less than 2 years, previous address: <span>{{application.previousAddress}}</span></p>
    </div>

    <p>Tell us some things you are looking for in a dog</p>

    <div class="form-item">
        <p>Preferred age: <span>{{application.preferredAge}}</span></p>
    </div>

    <div class="form-item">
        <p>Preferred sex: <span>{{application.preferredSex}}</span></p>
    </div>

    <div class="form-item">
        <p>Preferred activity level:</p>
        <p>{{application.preferredActivityLevel}}</p>
    </div>

    <div class="form-item">
        <p>Which of these is important:</p>
        <p>{{application.importantConsiderations}}</p>

        <p *ngIf="application.importantConsiderationsOther">Other: <span>{{application.importantConsiderationsOther}}</span></p>
    </div>

    <div class="form-item">
        <p>Why have you decided to adopt a cattle dog?</p>
        <p>{{application.reasonForAdopting}}</p>
    </div>

    <div class="form-item">
        <p>Which dog are you applying for?: <span>{{application.dogApplyingFor}}</span></p>
    </div>

    <div class="form-item">
        <p>Why do you feel this dog would be a good match for you?</p>
        <p>{{application.dogApplyingForExplain}}</p>
    </div>

    <div class="form-item">
        <p>If we feel that another dog in our foster home program might be a better match, are you willing to accept suggestions?: <span *ngIf="application.suggestOtherDogsOkay">Yes</span><span *ngIf="!application.suggestOtherDogsOkay">No</span></p>        
    </div>

    <div class="form-item">
        <p>Have you owned an ACD or other herding breed?: <span *ngIf="application.herdingBreedExperience">Yes</span><span *ngIf="!application.herdingBreedExperience">No</span></p>
    </div>
    
    <div class="form-item" *ngIf="!application.herdingBreedExperience">
        <p>If you have not owned an ACD, tell us what you know about the breed:</p>
        <p>{{application.breedKnowledge}}</p>
    </div>

    <div class="form-item">
        <p>What behaviors would you not be comfortable dealing with?</p>
        <p>{{application.behaviorConcerns}}</p>
    </div>

    <div class="form-item">
        <p>Do you currently have any pets?: <span *ngIf="application.hasPets">Yes</span><span *ngIf="!application.hasPets">No</span></p>
    </div>

    <div class="form-item" *ngIf="!application.hasPets">
        <p>Have you had pets in the past?: <span *ngIf="application.pastPets">Yes</span><span *ngIf="!application.pastPets">No</span></p>
    </div>

    <div class="form-item" *ngIf="application.hasPets || application.pastPets">
        <p *ngIf="application.hasPets">Please list type, breed, name and age of pets:</p>
        <p *ngIf="!application.hasPets && application.pastPets">Please list type and breed of pets:</p>
        <table>
            <thead>
                <tr>
                    <td>Type</td>
                    <td>Breed</td>
                    <td *ngIf="application.hasPets">Name</td>
                    <td *ngIf="application.hasPets">Age</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pet of application.pets">
                    <td>{{pet.type}}</td>
                    <td>{{pet.breed}}</td>
                    <td *ngIf="application.hasPets">{{pet.name}}</td>
                    <td *ngIf="application.hasPets">{{pet.age}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-container *ngIf="anyPets">
        <div class="form-item">
            <p>Is your dog on monthly heartworm prevention year round? (Please also answer for any previous dogs): <span *ngIf="application.heartwormPrevention">Yes</span><span *ngIf="!application.heartwormPrevention">No</span></p>
        </div>

        <div class="form-item" *ngIf="!application.heartwormPrevention">
            <p>Explain</p>
            <p>{{application.heartwormPreventionExplain}}</p>
        </div>

        <div class="form-item" *ngIf="application.heartwormPrevention">
            <p>What brand of prevention do you use?: <span>{{application.heartwormPreventionBrand}}</span></p>
        </div>

        <div class="form-item">
            <p>Is your dog on monthly flea/tick prevention? (Please also answer for any previous dogs if you do not currently have a dog): <span *ngIf="application.fleaTickPrevention">Yes</span><span *ngIf="!application.fleaTickPrevention">No</span></p>
        </div>

        <div class="form-item" *ngIf="!application.fleaTickPrevention">
            <p>Explain</p>
            <p>{{application.fleaTickPreventionExplain}}</p>
        </div>

        <div class="form-item" *ngIf="application.fleaTickPrevention">
            <p>What brand of prevention do you use?: <span>{{application.fleaTickPreventionBrand}}</span></p>
        </div>
        
        <div class="form-item">
            <p>Is your dog tested yearly for heartworm and flea/tick-borne diseases? (Please also answer for any previous dogs): <span *ngIf="application.yearlyTesting">Yes</span><span *ngIf="!application.yearlyTesting">No</span></p>
        </div>
    </ng-container>

    <div class="form-item" *ngIf="!application.hasPets">
        <p>If you do NOT currently have pets, please briefly explain the dog breeds you’ve had experience with and when</p>
        <p>{{application.petExperience}}</p>
    </div>

    <div class="form-item" *ngIf="application.hasPets || application.pastPets">
        <p>Are ALL your pets seen at least yearly by a veterinarian and kept up to date on all vaccines?: <span *ngIf="application.allPetsYearlyVetVisitAndVaccinesCurrent">Yes</span><span *ngIf="!application.allPetsYearlyVetVisitAndVaccinesCurrent">No</span></p>
    </div>
    
    <div class="form-item" *ngIf="!application.allPetsYearlyVetVisitAndVaccinesCurrent">
        <p>Explain</p>
        <p>{{application.allPetsYearlyVetVisitAndVaccinesCurrentExplain}}</p>
    </div>

    <div class="form-item" *ngIf="application.hasPets || application.pastPets">
        <p>Are all pets in your home spayed or neutered?: <span *ngIf="application.allPetsSpayedNeutered">Yes</span><span *ngIf="!application.allPetsSpayedNeutered">No</span></p>
    </div>

    <div class="form-item" *ngIf="!application.allPetsSpayedNeutered">
        <p>Explain</p>
        <p>{{application.allPetsSpayedNeuteredExplain}}</p>
    </div>

    <div class="form-item">
        <p>BACDR requires all adopters to attend a group puppy or basic obedience class after adoption. Are you willing to do this?: <span *ngIf="application.trainingPlan">Yes</span><span *ngIf="!application.trainingPlan">No</span></p>
    </div>

    <div class="form-item" *ngIf="!application.trainingPlan">
        <p>Explain</p>
        <p>{{application.trainingPlanExplain}}</p>
    </div>

    <div class="form-item">
        <p>Are you aware of the license, leash and animal laws of your community?: <span *ngIf="application.awareLicenseLeashAnimalLaws">Yes</span><span *ngIf="!application.awareLicenseLeashAnimalLaws">No</span></p>
    </div>

    <div class="form-item">
        <p>Do you have a fenced yard?: <span *ngIf="application.fencedYard">Yes</span><span *ngIf="!application.fencedYard">No</span></p>
    </div>

    <div class="form-item" *ngIf="application.fencedYard">
        <p>If yes, describe it (material and height): <span>{{application.fencedYardDescribe}}</span></p>
    </div>

    <div class="form-item">
        <p>How will you handle the dog’s exercise and bathroom needs?</p>
        <p>{{application.exerciseBathroomNeedsDescribe}}</p>
    </div>

    <div class="form-item">
        <p>Do you have a swimming pool or pond on your property?: <span *ngIf="application.poolOrPond">Yes</span><span *ngIf="!application.poolOrPond">No</span></p>
    </div>

    <div class="form-item" *ngIf="application.poolOrPond">
        <p>Describe</p>
        <p>{{application.poolOrPondDescribe}}</p>
    </div>

    <div class="form-item">
        <p>How many hours a day will dog be home alone?: <span>{{application.aloneHours}}</span></p>
    </div>

    <div class="form-item">
        <p>Where will dog be at this time?: <span>{{application.aloneLocation}}</span></p>
    </div>

    <div class="form-item">
        <p>Do you own a crate and know how to use it?: <span *ngIf="application.ownCrate">Yes</span><span *ngIf="!application.ownCrate">No</span></p>
    </div>

    <div class="form-item">
        <p>Where will the dog sleep at night?: <span>{{application.sleepLocation}}</span></p>
    </div>

    <p>Please note that ALL dogs adopted through BACDR are intended to be kept as INDOOR DOGS, not tethered, housed or kept for long periods of time outside regardless of the weather.</p>

    <div class="form-item">
        <p>Who would take care of dog when you go on vacation, etc?: <span>{{application.vacationCare}}</span></p>        
    </div>

    <div class="form-item">
        <p>Does anyone in your home have allergies to dogs?: <span *ngIf="application.allergies">Yes</span><span *ngIf="!application.allergies">No</span></p>
    </div>

    <div class="form-item">
        <p>Which household member will be responsible for the care, training, exercise and feeding needs of your dog?: <span>{{application.responsibleParty}}</span></p>
    </div>

    <div class="form-item">
        <p>Have you ever given up or returned an animal?: <span *ngIf="application.returnedAnimal">Yes</span><span *ngIf="!application.returnedAnimal">No</span></p>
    </div>

    <div class="form-item" *ngIf="application.returnedAnimal">
        <p>Explain</p>
        <p>{{application.returnedAnimalExplain}}</p>
    </div>

    <div class="form-item">
        <p>Are you willing to let us or one of our representatives do a home visit? (this is for us to get an idea of the environment the dog would be in and to suggest any possible changes that may make the dog’s transition easier): <span *ngIf="application.homeVisitOkay">Yes</span><span *ngIf="!application.homeVisitOkay">No</span></p>
    </div>

    <div class="form-item">
        <p>Name of Veterinarian or Clinic: <span>{{application.vetName}}</span></p>
        <p>Phone Number: <span>{{formatPhoneNumber(application.vetPhone)}}</span></p>
    </div>

    <p>Please call your veterinarian’s office to give permission for them to release info to us when we call them.</p>
    <p class="uppercase">When calling your veterinarian, we will be looking to ensure that your current and previous pets are/were kept up to date on appropriate vaccinations, heartworm testing, and that they have been kept on year-round heartworm preventative.</p>
    
    <div class="form-item">
        <p>What owner name is on file with veterinarian’s office?: <span>{{application.vetOwnerOnFile}}</span></p>
    </div>

    <div class="form-item">
        <p>List pet names on file there:</p>
        <p>{{application.vetPetsOnFile}}</p>
    </div>

    <div class="form-item" *ngIf="!application.hasPets && application.pastPets">
        <p>If no current pets, please list veterinarian used for any previous pets and the pets’ names</p>
        
        <p>Name: <span>{{application.vetPreviousPetsName}}</span></p>
        <p>Phone: <span>{{application.vetPreviousPetsPhone}}</span></p>
    </div>

    <div class="form-item" *ngIf="!application.hasPets && !application.pastPets">
        <p>If you have not had a pet, please list veterinarian you plan on using</p>

        <p>Name: <span>{{application.vetNoPetsName}}</span></p>
        <p>Phone: <span>{{application.vetNoPetsPhone}}</span></p>
    </div>

    <div class="form-item flex center-vertical">
        <p class="margin-right"><span *ngIf="application.certify">✅</span><span *ngIf="!application.certify">❌</span></p>
        <p class="small-print">By signing below, I certify that the information I have given is true and I realize that any misrepresentation of the facts may result in my losing the privilege of adoption a pet from Buckeye Australian Cattle Dog Rescue (BACDR). I understand that BACDR has the right to deny my request to adopt a dog for any situation that would be against our adoption policies, in violation of any state or local ordinance, or not in the best interest of the animal. I authorize the investigation of all statements in this application. I also authorize my veterinarian to release any information needed by BACDR to make a determination in this adoption.</p>
    </div>

    <div class="form-item">
        <p>Signature: <span>{{application.signature}}</span></p>
    </div>

    <p class="small-print">All information contained in this application will remain confidential and the property of BACDR.</p>
</div>