<div class="app-gen-form">
	<form (submit)="onSubmit()">
		<ul *ngIf="errors.length" class="errors">
			<li *ngFor="let error of errors" class="error">{{error}}</li>
		</ul>

		<div class="form-group">
			<label for="email" class="visually-hidden">Email</label>
			<input type="email" [ngClass]="{'error': emailError}" name="email" required [(ngModel)]="email" (change)="emailError = false" placeholder="Email" required>
		</div>
		<div class="form-group">
			<mat-checkbox name="sendEmail" [(ngModel)]="sendEmail">Send email?</mat-checkbox>
		</div>

		<input *ngIf="!submitted || (submitted && !response?.emailFound)" type="submit" class="submit" name="Generate Application URL" value="Generate Application URL">
	</form>

	<div *ngIf="submitted" class="submit-status">
		<div *ngIf="response?.emailFound" class="duplicate-email-found">
			<p>An application URL has already been created for this email address ({{response?.timestamp}}).</p>
			<p>Would you like to generate a new application URL?</p>
			<button (click)="onSubmit({ regenerateToken: true })">Generate New Application URL</button>
			<button (click)="clearForm()">Cancel</button>
		</div>
		<div *ngIf="response?.url" class="submit-success">
			<p>Application created: <a href="{{response?.url}}" target="_blank">{{response?.url}}</a></p>
			<p *ngIf="response?.emailSent">Email sent to applicant</p>
			<p *ngIf="response?.notificationSent">Notification email sent</p>
		</div>
		<div *ngIf="submitError" class="submit-error">Error: {{submitError}}</div>
	</div>
</div>