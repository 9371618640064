class Name {
	firstName: string;
	lastName: string;
}

class Child {
	age: string;
}

class Employment {
	applicant: string;
	employmentType: string;
	employer: string;
	occupation: string;
	employmentExplain: string;
}

class Pet {
	type: string;
	breed: string;
	name: string;
	age: string;
}

class Phone {
	number: string;
	type: string;
}

export class Application {

	constructor(applicationData) {
		Object.assign(this, applicationData);
	}

	confirmAtLeast18YearsOfAge: boolean;
	confirmHaveConsentOfAddAdultsInHousehold: boolean;
	confirmUnderstandRightToDenyOrAcceptApplication: boolean;
	adultsApplyingForAdoption: Array<Name>;
	numberAdultsInHousehold: string;
	numberChildren: string;
	childrenAges?: Array<Child>;
	childrenDogExperience?: boolean;
	country: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	phoneNumbers: Array<Phone>;
	email: string;
	applicantIntroduction: string;
	employment: Array<Employment>;
	rentOrOwn: string;
	homeType: string;
	homeTypeDescribe: string;
	landlordName?: string;
	landlordPhone?: string;
	landlordEmail?: string;
	durationLivedAtAddressYears: string;
	durationLivedAtAddressMonths: string;
	previousAddress?: string;
	preferredAge: Array<string>;
	preferredSex: string;
	preferredActivityLevel: Array<string>;
	importantConsiderations: Array<string>;
	importantConsiderationsOther: string;
	reasonForAdopting: string;
	dogApplyingFor: string;
	dogApplyingForExplain: string;
	suggestOtherDogsOkay: boolean;
	herdingBreedExperience: boolean;
	breedKnowledge?: string;
	behaviorConcerns: string;
	hasPets: boolean;
	pastPets?: boolean;
	pets?: Array<Pet>;
	heartwormPrevention?: boolean;
	heartwormPreventionExplain?: string;
	heartwormPreventionBrand?: string;
	fleaTickPrevention?: boolean;
	fleaTickPreventionExplain?: string;
	fleaTickPreventionBrand?: string;
	yearlyTesting?: boolean;
	petExperience?: string;
	allPetsYearlyVetVisitAndVaccinesCurrent?: boolean;
	allPetsYearlyVetVisitAndVaccinesCurrentExplain?: string;
	allPetsSpayedNeutered?: boolean;
	allPetsSpayedNeuteredExplain?: string;
	trainingPlan: boolean;
	trainingPlanExplain?: string;
	awareLicenseLeashAnimalLaws: boolean;
	fencedYard: boolean;
	fencedYardDescribe?: string;
	exerciseBathroomNeedsDescribe: string;
	poolOrPond: boolean;
	poolOrPondDescribe?: string;
	aloneHours: string;
	aloneLocation: string;
	ownCrate: boolean;
	sleepLocation: string;
	vacationCare: string;
	allergies: boolean;
	responsibleParty: string;
	returnedAnimal: boolean;
	returnedAnimalExplain?: string;
	homeVisitOkay: boolean;
	vetName: string;
	vetPhone: string;
	vetOwnerOnFile: string;
	vetPetsOnFile: Array<string>;
	vetPreviousPetsName?: string
	vetPreviousPetsPhone?: string;
	vetNoPetsName?: string;
	vetNoPetsPhone?: string;
	certify: boolean;
	signature: string;
	captcha: string;
	token: string;
	sendEmail: Boolean;
}
