import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { AdoptableDogsComponent } from './components/adoptable-dogs/adoptable-dogs.component';
import { FullWidthImgComponent } from './components/full-width-img/full-width-img.component';
import { GridComponent } from './components/grid/grid.component';
import { ContactComponent } from './components/contact/contact.component';
import { PupdatesComponent } from './components/pupdates/pupdates.component';
import { InstagramFeedComponent } from './components/instagram-feed/instagram-feed.component';
import { FancyImgComponent } from './components/fancy-img/fancy-img.component';
import { TextSectionComponent } from './components/text-section/text-section.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { SocialComponent } from './components/social/social.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule } from 'ng-recaptcha';
import { ApplicationComponent } from './components/application/application.component';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BannerComponent } from './components/banner/banner.component';
import { CreateApplicationPageComponent } from './pages/create-application-page/create-application-page.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { AnchorLinksComponent } from './components/anchor-links/anchor-links.component';
import { ReviewApplicationPageComponent } from './pages/review-application-page/review-application-page.component';
import { ApplicationPrettyPrintComponent } from './components/application-pretty-print/application-pretty-print.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FancyImgComponent,
    AdoptableDogsComponent,
    FullWidthImgComponent,
    GridComponent,
    ContactComponent,
    InstagramFeedComponent,
    TextSectionComponent,
    HomePageComponent,
    FooterComponent,
    SocialComponent,
    PupdatesComponent,
    ApplicationComponent,
    ApplicationPageComponent,
    BannerComponent,
    CreateApplicationPageComponent,
    ErrorListComponent,
    AnchorLinksComponent,
    ReviewApplicationPageComponent,
    ApplicationPrettyPrintComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
	FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
	BrowserAnimationsModule,
	RecaptchaModule,
	MatButtonModule,
	MatCheckboxModule,
	MatIconModule,
	MatRadioModule,
	MatSelectModule,
	MatFormFieldModule,
	MatInputModule,
	MatProgressSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
	  CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
