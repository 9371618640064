import { Component, Renderer2, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-instagram-feed',
  templateUrl: './instagram-feed.component.html',
  styleUrls: ['./instagram-feed.component.scss']
})
export class InstagramFeedComponent implements OnInit {

  constructor(
		private renderer2: Renderer2,
		@Inject(DOCUMENT) private _document
	) {}

  ngOnInit(): void {
	const s = this.renderer2.createElement('script');
	s.type = 'text/javascript';
	s.text = `(function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/embedscript/in.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialInstagramScript"));`;
	this.renderer2.appendChild(this._document.body, s);
  }
}
