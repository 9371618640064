<div class="pupdates">
	<app-text-section [title]="'Pupdates'">
		<p>See what BACDR graduates have been up to with their forever families!</p>
	</app-text-section>
	
	<button (click)="prev()" class="carousel-control prev" tabindex="0"><span class="material-icons">keyboard_arrow_left</span></button>
	<button (click)="next()" class="carousel-control next" tabindex="0"><span class="material-icons">keyboard_arrow_right</span></button>
	<div class="carousel-wrapper">
		<div class="carousel">
			<ul class="carousel-items" [attr.active]="activeSlide">
				<li *ngFor="let slide of slides; index as i;" class="carousel-item">
					<div class="item-left" style="background-image: url({{slide.image}})" alt="Boston"></div>
					<div class="item-right">
						<div class="slide-content">
							<h3>{{slide.title}}</h3>
							<p [innerHtml]="slide.content"></p>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<ul class="carousel-dots">
			<li *ngFor="let slide of slides; index as i;" [ngClass]="{'carousel-dot': true, 'active': activeSlide === i}" (click)="selectSlide(i)" tabindex="0"></li>
		</ul>
	</div>
</div>