import { Component, Renderer2, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
	constructor(
		private renderer2: Renderer2,
		@Inject(DOCUMENT) private _document
	) {}

  ngOnInit(): void {
	const s = this.renderer2.createElement('script');
	s.type = 'text/javascript';
	s.text = `var m1k2lxxo0n3vz1m; (function(d, t) { var s = d.createElement(t), options = { 'userName':'buckeyeacdrescue', 'formHash':'m1k2lxxo0n3vz1m', 'autoResize':true, 'height':'600', 'async':true, 'host':'wufoo.com', 'header':'show', 'ssl':true }; s.src = ('https:' == d.location.protocol ?'https://':'http://') + 'secure.wufoo.com/scripts/embed/form.js'; s.onload = s.onreadystatechange = function() { var rs = this.readyState; if (rs) if (rs != 'complete') if (rs != 'loaded') return; try { m1k2lxxo0n3vz1m = new WufooForm(); m1k2lxxo0n3vz1m.initialize(options); m1k2lxxo0n3vz1m.display(); } catch (e) { } }; var scr = d.getElementsByTagName(t)[0], par = scr.parentNode; par.insertBefore(s, scr); })(document, 'script');`;
	this.renderer2.appendChild(this._document.body, s);
  }
}