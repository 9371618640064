import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-anchor-links',
  templateUrl: './anchor-links.component.html',
  styleUrls: ['./anchor-links.component.scss']
})
export class AnchorLinksComponent implements OnInit {

	@Input() ids: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

}
