import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageComponent } from '../app/pages/home-page/home-page.component';
import { ApplicationPageComponent } from '../app/pages/application-page/application-page.component';
import { CreateApplicationPageComponent } from './pages/create-application-page/create-application-page.component';
import { ReviewApplicationPageComponent } from './pages/review-application-page/review-application-page.component';


const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'application', component: ApplicationPageComponent },
  { path: 'new-application', component: CreateApplicationPageComponent },
  { path: 'review-application', component: ReviewApplicationPageComponent},
  // { path: 'review-application/:applicationNumber', component: ReviewApplicationPageComponent},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
