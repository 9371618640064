<header>
	<div class="header">
		<a routerLink="">
			<img src="./assets/bacdr-logo.jpg" alt="Buckeye Australian Cattle Dog Rescue">
		</a>
		<div>
			<p class="upper">Buckeye Australian Cattle Dog Rescue</p>
			<p class="mission">Our mission is to save ACDs from Ohio shelters, provide all veterinary care, evaluate and place them in loving, permanent homes.<br>We are 501(c)(3).</p>
		</div>
    </div>
	<nav>
		<ul>
			<li><a routerLink="">Home</a></li>
			<li><a href="#donate">Donate</a></li>
			<li><a href="#contact">Contact</a></li>
			<li><a href="https://buckeyeacdrescue.myshopify.com/" target="_blank">Shop</a></li>
			<!-- <li class="push-right"><a href="mailto:buckeyeacdrescue@gmail.com" class="email">buckeyeacdrescue@gmail.com</a></li> -->
			<li><a href="https://www.facebook.com/BuckeyeACDRescue/" target="_blank"><span class="visually-hidden">Facebook</span><img src="./assets/svg/facebook.svg" alt="facebook" class="social-link"></a></li>
			<li><a href="https://www.instagram.com/buckeyeacdrescue/" target="_blank"><span class="visually-hidden">Instagram</span><img src="./assets/svg/instagram.svg" alt="instagram" class="social-link"></a></li>
		</ul>
	</nav>
</header>
<!-- <app-banner></app-banner> -->