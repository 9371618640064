import { Component, Input, OnInit } from '@angular/core';
import { Application } from '../../classes/application';

@Component({
  selector: 'app-application-pretty-print',
  templateUrl: './application-pretty-print.component.html',
  styleUrls: ['./application-pretty-print.component.scss']
})
export class ApplicationPrettyPrintComponent implements OnInit {
  
	@Input() application;

  employmentExplain: Boolean;
  anyPets: Boolean;

  constructor() { }

  ngOnInit(): void {
    this.employmentExplain = this.application.employment.filter( employmentInfo => employmentInfo.explanation).length > 0;
    this.anyPets = (this.application.hasPets || this.application.pastPets) && this.application.pets.filter( pet => pet.type === 'Dog').length > 0;
  }

  formatPhoneNumber(phoneNumberString): string {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }

}
