import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

	active: Boolean = true;
	remainingTime: string = '0:0:0:0';

	constructor() { }

	ngOnInit(): void {
		const countDownDate = new Date("Sep 16, 2020 23:59:59").getTime();
		const now = new Date().getTime();

		const distance = countDownDate - now;
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

		this.remainingTime = (days > 0 ? days + " day" + (days > 1 ? "s " : " ") : "") + (hours > 0 ? hours + " hour" + (hours > 1 ? "s " : " ") : "") + (minutes > 0 ? minutes + " minute" + (minutes > 1 ? "s " : " ") : "");

		if (distance < 0) {
			this.active = false;
		}
  	}
}
