<app-fancy-img [backgroundImg]="'assets/img/main.jpg'" [darkenBackground]="true" [parallax]="false">
	<h1>Buckeye Australian<br>Cattle Dog Rescue</h1>
	<p>Helping Australian Cattle Dogs in Ohio</p>
</app-fancy-img>
<app-adoptable-dogs></app-adoptable-dogs>
<app-fancy-img [backgroundImg]="'assets/img/story.jpg'" [darkenBackground]="true" [margin]="true" [parallax]="false" [backgroundPosition]="'top'">
	<h1>Our Story</h1>
	<p>Buckeye Australian Cattle Dog Rescue was founded in 2017 by a group of volunteers with years of experience in rescue, veterinary medicine, and the pet supply industry. Australian Cattle Dogs are our passion! Our mission is to save ACDs from Ohio shelters, provide all veterinary care, evaluate and place them in loving, permanent homes. We are 501(c)(3).</p>
	<p>We are a 100% foster-based rescue located in Ohio. All of our dogs live in foster homes with their families and pets. Our $350 adoption fee helps us ensure that all dogs are fully vetted before placement, including spay or neuter, testing for heartworm disease and tick-borne diseases, vaccinations, and microchips. Foster homes provide daily care as well as socialization and basic obedience skills.  Dogs are evaluated for several weeks in their foster home to learn their temperament and needs before placing them in their forever homes.</p>
	<p>All potential adopters must fill out an application, which can be requested through our contact form. Once your application is received, we will conduct a phone screening and check vet references. If you are matched with a dog, we will complete a home visit. All adoptions are given a two-week trial period before the adoption is made official.</p>
	<a href="#contact">Request an Application</a>
</app-fancy-img>
<app-text-section [title]="'Australian Cattle Dogs at a Glance'">
	<h3>Breed Description</h3>
	<p>Standing between 17 to 20 inches at the shoulder, the Australian Cattle Dog is a sturdy, hard-muscled herder of strength and agility. The ACD is born with a white coat that turns blue-gray or red. Both coat varieties feature distinctive mottling or specking patterns. ACDs have immense work drive and excel at hunting, chasing, and, of course, moving livestock. Their boundless energy and supple gait make them excellent running partners.</p>
	<p>ACDs are true-blue loyal, famously smart, ever alert, and wary of strangers. If an ACD isn’t challenged, he easily becomes bored and gets into mischief. It is recommended that ACD owners participate with their dog in some work, sport, or regular exercise to keep him mentally and physically fit.</p>
</app-text-section>
<app-full-width-img [imageSrc]="'assets/img/gravy.jpg'" [altText]="'test'"></app-full-width-img>
<app-text-section [title]="'How to Support Buckeye ACD Rescue'" id="donate"></app-text-section>
<app-grid></app-grid>
<app-contact></app-contact>
<app-pupdates></app-pupdates>
<app-social></app-social>
<app-instagram-feed></app-instagram-feed>