<div class="grid-wrapper">
	<div class="grid">
		<div class="grid-item">
			<h3>Donate Time</h3>
			<p>Buckeye ACD Rescue is a 100% foster-based rescue.</p>
			<p>We are always looking for loving foster homes around Akron, Columbus, and Dayton, OH. We rescue a wide range of ages and temperaments and need foster homes both with and without other pets. Herding dog experience is a plus, but not required. All vet care, food, and supplies are provided.</p>
			<p>If you are located in Ohio and are able to open your home and heart to a foster dog, please contact us.</p>
			<a href="#contact">Become a foster</a>
		</div>
		<div class="grid-item img">
			<img src="./assets/img/grid1.jpg">
		</div>
		<div class="grid-item img">
			<img src="./assets/img/grid2.jpg">
		</div>
		<div class="grid-item">
			<h3>Donate Money</h3>
			<p>Buckeye ACD Rescue runs entirely on the generous donations from supporters like you.</p>
			<p>We provide routine vet care, spay or neuter surgeries, preventative medications, treatments for illnesses and injuries, supplies, food, and more to the dogs in our care, and these things don't come cheap. We appreciate any size donation at any time.</p>
			<p>We welcome new ACDs to the rescue with a donation from a Freedom Sponsor. Your $40 donation gives them a leash and collar, and covers part of their pull fee from the shelter to give them a fresh start. </p>
			<p>Buckeye Australian Cattle Dog Rescue is a 501(c)(3) non-profit.</p>
			<p>Your donation is tax deductible.</p>
			<a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=buckeyeacdrescue@gmail.com&item_name=Buckeye Australian Cattle Dog Rescue&currency_code=USD" target="_blank">Make a donation</a>
		</div>
	</div>
</div>