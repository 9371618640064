import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-pupdates',
  templateUrl: './pupdates.component.html',
  styleUrls: ['./pupdates.component.scss']
})
export class PupdatesComponent implements OnInit {
	carousel;
	activeSlide:number = 0;
	slides = [
		{
			image: "./assets/img/pupdate-boston.jpg",
			title: "Boston",
			content: "Boston has been spending his days working to keep up with his hobbies and training. He loves doing tricks for fruits and veggies, playing ball and catching frisbees in the yard, and running around with his friends on the beach. He passed his obedience classes with flying colors and has been practicing agility or solving puzzle toys to keep sharp. After a long day of play Boss loves to curl up and cuddle with his humans and cats. Our little family couldn’t be happier!"
		},
		{
			image: "./assets/img/pupdate-colbie.jpg",
			title: "Colbie",
			content: "Colbie (formerly Lucy from the Charlie Brown litter) has grown into a spunky, energetic girl who loves to go on adventures with her parents and her big brother. Her favorite activities include kayaking, belly rubs, swimming like a fish, and making her family laugh. She is never short on enthusiasm or the love she gives to those around her, especially the family cat. Colbie sure is one special pup! 🐶❤️"
		},
		{
			image: "./assets/img/pupdate-chase.jpg",
			title: "Chase",
			content: "Chase has settled in perfectly with our family. He and I passed the first level agility training in which he was one of the class stars! We hope to continue with the next level. He socialized well with the other dogs and was a bit of a flirt! He loves to &quot;chase&quot; everything - leaves, toys, and, in his mind, squirrels."
		}
	];

	constructor() { }

	ngOnInit(): void {
	}

	prev() {
		this.activeSlide = this.activeSlide > 0 ? this.activeSlide - 1 : this.slides.length - 1;
	}
	next() {
		this.activeSlide = this.activeSlide < 2 ? this.activeSlide + 1 : 0;
	}

	selectSlide(index): void {
		this.activeSlide = index;
	}
}
