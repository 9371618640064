import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss']
})
export class ErrorListComponent implements OnInit {

	@Input() errors: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

	scroll(id: string) {
		const el = (<HTMLInputElement>document.getElementById(id));
		if (el) {
			el.scrollIntoView();
		}
	}

}
