import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-width-img',
  templateUrl: './full-width-img.component.html',
  styleUrls: ['./full-width-img.component.scss']
})
export class FullWidthImgComponent implements OnInit {
  @Input() imageSrc:string;
  @Input() altText:string;

  constructor() { }

  ngOnInit(): void {
  }

}
