import { Component, OnInit } from '@angular/core';
import { AdoptableDogsService } from '../../services/adoptable-dogs.service';

@Component({
  selector: 'app-adoptable-dogs',
  templateUrl: './adoptable-dogs.component.html',
  styleUrls: ['./adoptable-dogs.component.scss']
})
export class AdoptableDogsComponent implements OnInit {
	loading = true;
	pets = [];
	error = false;

  constructor(private adoptableDogsService: AdoptableDogsService) {}

  ngOnInit(): void {
	this.adoptableDogsService.get().then((res) => {
		this.loading = false;
		if (res) {
			this.pets = res;
		} else {
			this.error = true;
		}
	});
  }
}
